import * as t from 'io-ts'

import { TextField } from './variants/text'
import { CheckboxField } from './variants/checkbox'
import { RadioGroup, RadioOption } from './variants/radio'
import { DateField } from './variants/date'
import { PhoneNumberField } from './variants/phone'

export const FormField = t.union([
	TextField,
	CheckboxField,
	RadioGroup,
	RadioOption,
	DateField,
	PhoneNumberField,
])
export type FormField = t.TypeOf<typeof FormField>

export const SingleMappableField = t.union([DateField, PhoneNumberField])
export type SingleMappableField = t.TypeOf<typeof SingleMappableField>

export const MappableField = t.union([TextField, CheckboxField, RadioGroup])
export type MappableField = t.TypeOf<typeof MappableField>

export const UnmappableField = t.union([
	TextField,
	CheckboxField,
	RadioGroup,
	DateField,
	PhoneNumberField,
])
export type UnmappableField = t.TypeOf<typeof UnmappableField>

export const VisibleField = t.union([
	TextField,
	CheckboxField,
	RadioOption,
	DateField,
	PhoneNumberField,
])
export type VisibleField = t.TypeOf<typeof VisibleField>

export const FormRegistry = t.record(t.string, FormField)
export type FormRegistry = t.TypeOf<typeof FormRegistry>
