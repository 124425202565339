import {
	DataElement,
	QualifiedDataElement,
	allElementIds,
	allEnumValueIds,
	eqQualifiedDataElement,
} from './elements'

import { flow } from 'fp-ts/lib/function'
import { pipe } from 'fp-ts/lib/function'
import * as A from 'fp-ts/lib/Array'
import * as O from 'fp-ts/lib/Option'
import * as NEA from 'fp-ts/lib/NonEmptyArray'

type DataElementPath = NEA.NonEmptyArray<DataElement>

const findRootElements = A.filter((x: DataElement) => x.parent == null)

const findChildren = (id: string) => NEA.filter((x: DataElement) => x.parent === id)

const getPaths = (model: NEA.NonEmptyArray<DataElement>): DataElementPath[] => {
	const walk = (parent: DataElement): NEA.NonEmptyArray<DataElementPath> => {
		const children = findChildren(parent.id)(model)

		return pipe(
			children,
			O.fold(
				() => NEA.of(NEA.of(parent)),
				NEA.chain((child) =>
					pipe(
						walk(child),
						NEA.map((path) => NEA.cons(parent, path))
					)
				)
			)
		)
	}

	return findRootElements(model).flatMap(walk)
}

export const findByID =
	<I, R extends { id: I }>(xs: R[]) =>
	(id?: I) =>
		A.findFirst<R>((x) => x.id === id)(xs)

export const findParent = (xs: QualifiedDataElement[]) => (x: QualifiedDataElement) =>
	pipe(x.id.split('.').slice(0, -1).join('.'), findByID(xs))

const getQualifiedName = flow(
	A.map((x: DataElement) => x.name),
	(xs) => xs.join('.')
)

const getQualifiedID = flow(
	A.map((x: DataElement) => x.id),
	(xs) => xs.join('.')
)

const lowercaseIds = flow(
	allElementIds.modify((id) => id.toLowerCase()),
	allEnumValueIds.modify((id) => id.toLowerCase())
)

export const constructDataModel = (elements: DataElement[]): QualifiedDataElement[] =>
	pipe(
		elements as NEA.NonEmptyArray<DataElement>,
		getPaths,
		A.map((path): QualifiedDataElement => {
			const { id, name, parent, ...leaf } = NEA.last(path)
			return {
				id: getQualifiedID(path),
				name: getQualifiedName(path),
				...leaf,
			}
		}),
		lowercaseIds,
		A.uniq(eqQualifiedDataElement)
	)
