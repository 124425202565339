import * as t from 'io-ts'

import { Lens } from 'monocle-ts'

export const GoogleUser = t.type({
	email: t.string,
	expiresAt: t.number,
	id: t.string,
	imageUrl: t.string,
	name: t.string,
	tokenId: t.string,
})

export type GoogleUser = t.TypeOf<typeof GoogleUser>

const lenses = Lens.fromProp<GoogleUser>()
export const email = lenses('email')
export const expiresAt = lenses('expiresAt')
export const id = lenses('id')
export const imageUrl = lenses('imageUrl')
export const name = lenses('name')
export const tokenId = lenses('tokenId')
