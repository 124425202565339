import axios, { AxiosResponse, AxiosRequestConfig, AxiosError } from 'axios'

import { pipe } from 'fp-ts/lib/function'
import * as TE from 'fp-ts/lib/TaskEither'
import * as I from 'io-ts'

import * as Err from './errors'

export const getBFFRoute = () => {
	return window.location.origin.includes('localhost')
		? `${window.location.origin}`.replace(':8081', ':3600').replace(':8080', ':3600')
		: window.location.origin
}
export const getCredentials = () => {
	return window.location.origin.includes('localhost') ? { withCredentials: true } : {}
}

export const createGetRequest = (url: string, config?: AxiosRequestConfig) =>
	TE.tryCatch(
		() => axios.get(url, config),
		(e) => new Err.HttpError(e as AxiosError<any>)
	)

export const createPostRequest =
	<T>(url: string) =>
	(data: T, config?: AxiosRequestConfig) =>
		TE.tryCatch(
			() => axios.post(url, data, config),
			(e) => new Err.HttpError(e as AxiosError<any>)
		)

export const decodeResponse =
	<T>(decoder: I.Decoder<unknown, T>) =>
	(response: AxiosResponse<unknown>) =>
		pipe(
			response,
			(r) => r.data,
			decoder.decode,
			TE.fromEither,
			TE.mapLeft((e) => new Err.RuntimeValidationError(e))
		)
