import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { useAuthStore } from '@/modules/auth/store'
import { isValidGoogleUser } from '@/modules/auth/validation'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'

const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		name: 'Home',
		component: () => import('@/pages/Home'),
	},
	{
		path: '/files',
		name: 'Files',
		component: () => import('@/pages/Files'),
	},
	{
		path: '/editor/:id',
		name: 'Editor',
		component: () => import('@/pages/Editor'),
	},
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
})

router.beforeEach((to, from, next) => {
	const authStore = useAuthStore()
	const user = authStore.state.user

	pipe(
		user,
		O.fold(() => false, isValidGoogleUser),
		(validUser) => {
			if (
				from.query.returnUrl &&
				to?.path !== from.query.returnUrl &&
				from.query.returnUrl !== '/'
			) {
				to.query.returnUrl = from.query.returnUrl
			}
			if (to?.redirectedFrom?.path && to?.redirectedFrom?.path !== '/') {
				to.query.returnUrl = to?.redirectedFrom?.path
			}
			if (validUser || to.name === 'Home') {
				next()
			} else {
				next({ name: 'Home' })
			}
		}
	)
})

export default router
