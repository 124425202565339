import { SortOption } from '@/models/form/sorts'

type sortOption = Date | string | string[] | number | null

export const nextSortOption = (currentOption: SortOption): SortOption => {
	switch (currentOption) {
		case SortOption.NONE:
			return SortOption.ASCENDING
		case SortOption.ASCENDING:
			return SortOption.DESCENDING
		default:
			return SortOption.NONE
	}
}

export const sortByOption = (option: SortOption) => (A: sortOption, B: sortOption) => {
	if (!A) return -1
	if (!B) return 1

	const convertType = (option: sortOption): Date | string | number => {
		switch (typeof option) {
			case 'string':
				return option.toLowerCase()
			case 'object':
				return JSON.stringify(option)
			default:
				return option
		}
	}

	const valueA = convertType(A)
	const valueB = convertType(B)

	switch (option) {
		case SortOption.ASCENDING:
			return valueA < valueB ? -1 : 1
		case SortOption.DESCENDING:
			return valueA < valueB ? 1 : -1
		default:
			return 0
	}
}
