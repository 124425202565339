import * as t from 'io-ts'

import { ServerDiscoveredNameMetadata, ServerNameMetadata } from '../../metadata'
import { ServerRequiredConstraint } from '../../constraints'
import { ServerDimensionalTrait } from '../../traits/dimensional'
import { ServerFirstClassTrait } from '../../traits/first-class'
import { ServerPaginatedTrait } from '../../traits/paginated'
import { ServerPositionalTrait } from '../../traits/positional'
import { ServerSingleMappableTrait } from '../../traits/single-mappable'
import { ServerUnmappableTrait } from '../../traits/unmappable'
import { ServerPhoneNumberFormat } from './format'

// prettier-ignore
export const ServerPhoneNumberField = t.intersection([
	t.type({
		type: t.literal('PHONE_NUMBER_FIELD'),
	}),
	t.partial({
		section: t.union([ServerPhoneNumberFormat, t.null]),
		constraints: ServerRequiredConstraint,
		metadata: t.intersection([
			ServerDiscoveredNameMetadata,
			ServerNameMetadata
		]),
	}),
	t.intersection([
		ServerDimensionalTrait,
		ServerFirstClassTrait,
		ServerPaginatedTrait,
		ServerPositionalTrait,
		ServerSingleMappableTrait
	]),
	t.intersection([
		ServerSingleMappableTrait,
		ServerUnmappableTrait
	])
])

export type ServerPhoneNumberField = t.TypeOf<typeof ServerPhoneNumberField>
