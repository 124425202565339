import { flow } from 'fp-ts/lib/function'
import * as A from 'fp-ts/lib/Array'
import * as O from 'fp-ts/lib/Option'
import { Iso } from 'monocle-ts'

import { ServerElementRef } from './element.server'
import { ElementRef } from './element'
import * as FQP from './path'

const cons = (parent: string, child: ServerElementRef): ServerElementRef => ({
	element: parent,
	then: child,
})

const toArray =
	(acc: string[]) =>
	(ref: ServerElementRef): string[] => {
		return !ref?.element ? acc : toArray([...acc, ref.element])(ref.then)
	}

const serverRefArrayIso = new Iso<ServerElementRef, string[]>(
	toArray([]),
	A.reduceRight(undefined, cons)
)

const refArrayIso = new Iso<string[], ElementRef>(
	(ids) => (ids.length > 0 ? O.some(FQP.concat(ids)) : O.none),
	O.fold(() => [], FQP.split)
)

export const elementRefIso = new Iso<ServerElementRef, ElementRef>(
	flow(serverRefArrayIso.get, refArrayIso.get),
	flow(refArrayIso.reverseGet, serverRefArrayIso.reverseGet)
)
