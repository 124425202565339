import * as t from 'io-ts'

import { ServerTextField } from './variants/text'
import { ServerCheckboxField } from './variants/checkbox'
import { ServerRadioField } from './variants/radio'
import { ServerDateField } from './variants/date'
import { ServerPhoneNumberField } from './variants/phone'

export const ServerFormField = t.union([
	ServerTextField,
	ServerCheckboxField,
	ServerRadioField,
	ServerDateField,
	ServerPhoneNumberField,
])

export type ServerFormField = t.TypeOf<typeof ServerFormField>
