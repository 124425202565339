import { Iso } from 'monocle-ts'

import { ServerDimensionalTrait } from './dimensional.server'
import { DimensionalTrait } from './dimensional'
import { formFieldDimensionsIso } from '../../dimensions'

export const dimensionalTraitIso = new Iso<ServerDimensionalTrait, DimensionalTrait>(
	(trait) => ({ dimensions: formFieldDimensionsIso.get(trait.size) }),
	(trait) => ({ size: formFieldDimensionsIso.reverseGet(trait.dimensions) })
)
