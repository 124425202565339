import * as t from 'io-ts'

export const ServerPhoneNumberFormat = t.keyof({
	full: null,
	area_code: null,
	prefix: null,
	suffix: null,
	country_code: null,
})

export type ServerPhoneNumberFormat = t.TypeOf<typeof ServerPhoneNumberFormat>

export const PhoneNumberFormat = t.keyof({
	FULL: null,
	AREA_CODE: null,
	PREFIX: null,
	SUFFIX: null,
	COUNTRY_CODE: null,
})

export type PhoneNumberFormat = t.TypeOf<typeof PhoneNumberFormat>

export const phoneNumberFormatOptions = [
	{ value: 'FULL', label: 'Full' },
	{ value: 'AREA_CODE', label: 'Area Code' },
	{ value: 'PREFIX', label: 'Prefix' },
	{ value: 'SUFFIX', label: 'Suffix' },
	{ value: 'COUNTRY_CODE', label: 'Country Code' },
]
