import { v4 as uuidv4 } from 'uuid'

import * as O from 'fp-ts/lib/Option'
import { UUID } from 'io-ts-types/lib/UUID'
import { Iso, Lens } from 'monocle-ts'

import {
	ServerDiscoveredNameMetadata,
	ServerNameMetadata,
	ServerIdMetadata,
} from './metadata.server'
import { DiscoveredNameMetadata, NameMetadata, IdMetadata } from './metadata'

export const discoveredNameMetadataIso = new Iso<
	ServerDiscoveredNameMetadata,
	DiscoveredNameMetadata
>(
	(metadata) => ({ discoveredName: O.fromNullable(metadata.discovered_fully_qualified_name) }),
	(metadata) => ({ discovered_fully_qualified_name: O.toNullable(metadata.discoveredName) })
)

export const nameMetadataIso = new Iso<ServerNameMetadata, NameMetadata>(
	(metadata) => ({ name: O.fromNullable(metadata.name) }),
	(metadata) => ({ name: O.toNullable(metadata.name) })
)

export const idMetadataIso = new Iso<ServerIdMetadata, IdMetadata>(
	(metadata) => ({ id: metadata.id ?? (uuidv4() as UUID) }),
	(metadata) => ({ id: metadata.id })
)

export const discoveredName = Lens.fromProp<DiscoveredNameMetadata>()('discoveredName')
export const name = Lens.fromProp<NameMetadata>()('name')
export const id = Lens.fromProp<IdMetadata>()('id')
