import { FormMetaDataKey } from '@/models/form/metadata'

export enum SortOption {
	'NONE',
	'ASCENDING',
	'DESCENDING',
}

export interface FormsListColumnSort {
	name: FormMetaDataKey
	option: SortOption
}
