import formatDate from 'date-fns/formatDistanceToNowStrict'

import { flow, pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
import { UUID } from 'io-ts-types/lib/UUID'
import * as t from 'io-ts'
import { Optional } from 'monocle-ts'

import { Form, FORM_STATUS, FormType } from './form'
import { formStatus, formType, lastModified, updatedBy } from './optics'
import { date as DateF } from 'io-ts-types/lib/date'

export const LastModified = t.type({ display: t.string, date: t.union([DateF, t.null]) })
export type LastModified = t.TypeOf<typeof LastModified>

export const FormMetadata = t.type({
	id: UUID,
	name: t.string,
	pageCount: t.number,
	updatedBy: t.string,
	formType: t.string,
	formStatus: t.string,
	lastModified: LastModified,
})

export type FormMetadata = t.TypeOf<typeof FormMetadata>

export type FormMetaDataKey = keyof FormMetadata

/**
 * Format a Date into a string representing the distance between the date and
 * now in words. E.g. "7 hours ago", "49 seconds ago"
 */
const humanizeDate = flow(formatDate, (d) => d + ' ago')

/**
 * Formats a Date on a [[Form]] into a string representing the distance between
 * the date and now in words. Since dates are nullable fields, if the date
 * accessed by the Optional does not exist, "unknown" is returned.
 */
const humanizeFormDate = (form: Form) => (optional: Optional<Form, Date>) =>
	pipe(
		form,
		optional.getOption,
		O.fold(() => 'unknown', humanizeDate)
	)

/**
 * Projects the metadata fields from a [[Form]] and converts the dates into a
 * human-readable format.
 */
export function extractFormMetadata(form: Form): FormMetadata {
	const format = humanizeFormDate(form)
	return {
		...form,
		formType: pipe(
			form,
			formType.getOption,
			O.fold(
				() => FormType.MANUAL,
				(formType: string) =>
					Object.keys(FormType).includes(formType.toUpperCase())
						? formType.toUpperCase()
						: FormType.MANUAL
			)
		),
		formStatus: pipe(
			form,
			formStatus.getOption,
			O.fold(
				() => FORM_STATUS.DRAFT,
				(status: string) =>
					status.toUpperCase() === FORM_STATUS.PUBLISHED ? FORM_STATUS.PUBLISHED : FORM_STATUS.DRAFT
			)
		),
		lastModified: {
			display: format(lastModified),
			date: pipe(
				form,
				lastModified.getOption,
				O.fold(
					() => null,
					(lastModified) => lastModified
				)
			),
		},
		updatedBy: pipe(
			form,
			updatedBy.getOption,
			O.getOrElse(() => '')
		),
	}
}
