import * as t from 'io-ts'

export const ServerAdditionalInformation = t.intersection([
	t.type({
		$schema: t.string,
		$id: t.string,
		type: t.string,
		properties: t.any,
	}),
	t.partial({
		title: t.union([t.string, t.null]),
		description: t.union([t.string, t.null]),
		required: t.union([t.array(t.string), t.null]),
	}),
])

export type ServerAdditionalInformation = t.TypeOf<typeof ServerAdditionalInformation>
