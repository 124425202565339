import * as O from 'fp-ts/lib/Option'
import { UUID } from 'io-ts-types/lib/UUID'
import * as t from 'io-ts'

import { DiscoveredNameMetadata, NameMetadata } from '../../metadata'
import { RequiredConstraint } from '../../constraints'
import { FormFieldPosition } from '../../position'
import { FormFieldDimensions } from '../../dimensions'
import { DimensionalTrait } from '../../traits/dimensional'
import { FirstClassTrait } from '../../traits/first-class'
import { PaginatedTrait } from '../../traits/paginated'
import { PositionalTrait } from '../../traits/positional'
import { UnmappableTrait } from '../../traits/unmappable'
import { ElementRef } from '../../element'
import { PhoneNumberField } from '@/models/form/definition/field/variants/phone'
import { DateField } from '@/models/form/definition/field/variants/date'

// prettier-ignore
export const TextField = t.intersection([
	t.type({
		type: t.literal('TEXT_FIELD'),
		multiline: t.boolean,
		defaultValue: t.string,
		delimiter: t.string,
		element: t.array(ElementRef),
		constraints: RequiredConstraint,
		metadata: t.intersection([
			DiscoveredNameMetadata,
			NameMetadata
		]),
	}),
	t.intersection([
		DimensionalTrait,
		FirstClassTrait,
		PaginatedTrait,
		PositionalTrait,
		UnmappableTrait,
	]),
])

export type TextField = t.TypeOf<typeof TextField>

export const createTextField = (
	id: UUID,
	name: O.Option<string>,
	page: number,
	position: FormFieldPosition,
	dimensions: FormFieldDimensions
): TextField => ({
	id,
	type: 'TEXT_FIELD',
	multiline: false,
	mappable: true,
	element: [],
	defaultValue: '',
	delimiter: '',
	constraints: {
		required: false,
	},
	metadata: {
		discoveredName: O.none,
		name,
	},
	page,
	position,
	dimensions,
})

export function convertToTextField(field: TextField | PhoneNumberField | DateField): TextField {
	return field.type === 'TEXT_FIELD'
		? field
		: createTextField(field.id, field.metadata.name, field.page, field.position, field.dimensions)
}
