import { v4 as uuidv4 } from 'uuid'

import * as O from 'fp-ts/lib/Option'
import { UUID } from 'io-ts-types/lib/UUID'
import { option } from 'io-ts-types/lib/option'
import * as t from 'io-ts'

import { DiscoveredNameMetadata, NameMetadata } from '../../metadata'
import { RequiredConstraint } from '../../constraints'
import { DimensionalTrait } from '../../traits/dimensional'
import { FirstClassTrait } from '../../traits/first-class'
import { MappableTrait } from '../../traits/mappable'
import { PaginatedTrait } from '../../traits/paginated'
import { PositionalTrait } from '../../traits/positional'
import { UnmappableTrait } from '../../traits/unmappable'
import { FormFieldPosition } from '../../position'
import { FormFieldDimensions } from '../../dimensions'

export const enumConditionalValue = (
	ids: O.Option<string>[]
): { type: 'ENUM'; ids: O.Option<string>[] } => ({ type: 'ENUM', ids })

export const booleanConditionalValue = (value: boolean): { type: 'BOOLEAN'; value: boolean } => ({
	type: 'BOOLEAN',
	value,
})

export const RadioEnumConditional = t.type({
	type: t.literal('ENUM'),
	ids: t.array(option(t.string)),
})

export type RadioEnumConditional = t.TypeOf<typeof RadioEnumConditional>

export const RadioBooleanConditional = t.type({
	type: t.literal('BOOLEAN'),
	value: t.boolean,
})

export type RadioBooleanConditional = t.TypeOf<typeof RadioBooleanConditional>

export const RadioConditional = t.type({
	conditional: option(t.union([RadioEnumConditional, RadioBooleanConditional])),
})

export type RadioConditional = t.TypeOf<typeof RadioConditional>

// prettier-ignore
export const RadioOption = t.intersection([
	t.type({
		type: t.literal('RADIO_OPTION'),
		parent: UUID,
		value: t.string,
		metadata: NameMetadata,
	}),
	t.intersection([
		RadioConditional,
		DimensionalTrait,
		FirstClassTrait,
		PaginatedTrait,
		PositionalTrait,
	])
])

export type RadioOption = t.TypeOf<typeof RadioOption>

export const createRadioOption = (
	id: UUID,
	parent: UUID,
	name: O.Option<string>,
	page: number,
	position: FormFieldPosition,
	dimensions: FormFieldDimensions
): RadioOption => ({
	id,
	parent,
	type: 'RADIO_OPTION',
	value: O.getOrElse(() => uuidv4())(name),
	conditional: O.none,
	metadata: { name },
	page,
	position,
	dimensions,
})

// prettier-ignore
export const RadioGroup = t.intersection([
	t.type({
		type: t.literal('RADIO_GROUP'),
		constraints: RequiredConstraint,
		metadata: t.intersection([
			DiscoveredNameMetadata,
			NameMetadata,
		]),
	}),
	t.intersection([
		FirstClassTrait,
		MappableTrait,
		UnmappableTrait
	])
])

export type RadioGroup = t.TypeOf<typeof RadioGroup>

export const createRadioGroup = (id: UUID, name: O.Option<string>): RadioGroup => ({
	id,
	type: 'RADIO_GROUP',
	mappable: true,
	constraints: {
		required: false,
	},
	metadata: {
		discoveredName: O.none,
		name,
	},
	element: [],
})
