<template>
	<transition-group name="fade" mode="out-in" tag="div" class="toasts-container">
		<toast-message v-for="msg of liveQueue" :message="msg.message" :key="msg.id" />
	</transition-group>
</template>

<script lang="ts">
import { defineComponent, onBeforeUnmount } from 'vue'
import ToastMessage from '@/components/Toast/Message'
import { useMessageQueue, clearMessageQueue } from '@/components/Toast/Display/service'

export default defineComponent({
	name: 'ToastDisplay',
	components: {
		[ToastMessage.name]: ToastMessage,
	},
	props: {
		displayTime: { type: Number, required: true }, // milliseconds
		maxDisplayed: { type: Number, required: true },
	},
	setup(props) {
		onBeforeUnmount(() => clearMessageQueue())
		return useMessageQueue(props.maxDisplayed, props.displayTime)
	},
})
</script>

<style lang="scss" scoped>
@import '@/styles/colors.scss';
.toasts-container {
	position: absolute;
	bottom: 8px;
	width: 100vw;
	display: flex;
	flex-direction: column;
	z-index: 2000;
}

.fade-enter-active {
	transition: all 400ms ease-out;
}

.fade-leave-active {
	transition: all 200ms ease-out;
}

.fade-enter {
	opacity: 0;
	transform: translateY(8px);
}

.fade-leave-to {
	opacity: 0;
	transform: translateY(-8px);
}
</style>
