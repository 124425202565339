import { flow } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
import { Iso, Lens } from 'monocle-ts'

import { ServerDateField } from './date.server'
import { DateField } from './date'

import { discoveredNameMetadataIso, nameMetadataIso } from '../../metadata'
import { requiredConstraintIso } from '../../constraints'
import { dimensionalTraitIso } from '../../traits/dimensional'
import { firstClassTraitIso } from '../../traits/first-class'
import { paginatedTraitIso } from '../../traits/paginated'
import { positionalTraitIso } from '../../traits/positional'
import { singleMappableTraitIso } from '../../traits/single-mappable'
import { unmappableTraitIso } from '../../traits/unmappable'

export const format = Lens.fromProp<DateField>()('format')

export const element = Lens.fromProp<DateField>()('element')

export const constraints = {
	required: Lens.fromPath<DateField>()(['constraints', 'required']),
}

export const metadata = {
	discoveredName: Lens.fromPath<DateField>()(['metadata', 'discoveredName']),
	name: Lens.fromPath<DateField>()(['metadata', 'name']),
}

const defaultNameToDiscoveredName = (field: DateField) => {
	const discoveredName = metadata.discoveredName.get(field)
	const name = metadata.name.get(field)
	return metadata.name.set(O.alt(() => discoveredName)(name))(field)
}

export const dateFieldIso = new Iso<ServerDateField, DateField>(
	flow(
		(field) => ({
			type: field.type,
			format: field.format ?? '',
			constraints: {
				...field.constraints, // preserve unused constraints
				...requiredConstraintIso.get({ ...field.constraints }),
			},
			metadata: {
				...field.metadata, // preserve unused metadata
				...discoveredNameMetadataIso.get({ ...field.metadata }),
				...nameMetadataIso.get({ ...field.metadata }),
			},
			...dimensionalTraitIso.get(field),
			...firstClassTraitIso.get(field),
			...paginatedTraitIso.get(field),
			...positionalTraitIso(field.size.y).get(field),
			...singleMappableTraitIso.get(field),
			...unmappableTraitIso.get(field),
		}),
		defaultNameToDiscoveredName
	),
	(field) => {
		const { discoveredName, name, ...unusedMetadata } = field.metadata
		const { required, ...unusedConstraints } = field.constraints

		return {
			type: field.type,
			format: field.format
				// Convert 'Week-Based Year' to 'Year of Era'
				.replace(/Y/g, 'y')
				// Convert 'Minute of Hour' to 'Month of Year'
				.replace(/m/g, 'M')
				// Convert 'Day of Year' to 'Day of Month'
				.replace(/D/g, 'd'),
			constraints: {
				...unusedConstraints,
				...requiredConstraintIso.reverseGet(field.constraints),
			},
			metadata: {
				...unusedMetadata,
				...discoveredNameMetadataIso.reverseGet(field.metadata),
				...nameMetadataIso.reverseGet(field.metadata),
			},
			...dimensionalTraitIso.reverseGet(field),
			...firstClassTraitIso.reverseGet(field),
			...paginatedTraitIso.reverseGet(field),
			...positionalTraitIso(field.dimensions.height).reverseGet(field),
			...singleMappableTraitIso.reverseGet(field),
			...unmappableTraitIso.reverseGet(field),
		}
	}
)
