import * as t from 'io-ts'

import { ServerFormDefinitionMetadata } from './metadata'
import { ServerFormField } from './field/field.server'

export const ServerFormDefinition = t.partial({
	fields: t.union([t.array(ServerFormField), t.null]),
	metadata: t.union([ServerFormDefinitionMetadata, t.null]),
})

export type ServerFormDefinition = t.TypeOf<typeof ServerFormDefinition>
