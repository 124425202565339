import { RequestError as HttpRequestError, getRequestErrorMessage } from '@/modules/http'

export type MyndConfigError = { type: 'MYND_CONFIG' }
export type RequestError = { type: 'REQUEST'; context: HttpRequestError }
export type ApiError = MyndConfigError | RequestError

/**
 * Data constructor for [[ApiError]].
 */
export function myndConfigError(): ApiError {
	return { type: 'MYND_CONFIG' }
}

/**
 * Data constructor for [[ApiError]].
 */
export function requestError(context: HttpRequestError): ApiError {
	return { type: 'REQUEST', context }
}

/**
 * Extracts an error message from [[ApiError]].
 */
export function getApiErrorMessage(error: ApiError): string {
	switch (error.type) {
		case 'MYND_CONFIG':
			return 'Unable to read values from Mynd Config'
		case 'REQUEST':
			return getRequestErrorMessage(error.context)
	}
}
