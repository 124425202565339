/**
 * These types are specifically for the input selection
 */
export type PropertyInputSelectionTypes =
	| 'string'
	| 'number'
	| 'integer'
	| 'boolean'
	| 'date'
	| 'phone'
	| 'array'
	| 'enum'

/**
 * These types are valid json schema types
 */
export type PropertySchemaTypes =
	| 'string'
	| 'number'
	| 'boolean'
	| 'object'
	| 'integer'
	| 'array'
	| 'enum'
	| null

/**
 * These types are specific to date for different types of date validation
 */
export type DateFormatTypes =
	| 'date'
	| 'past_date'
	| 'past_or_present_date'
	| 'future_or_present_date'
	| 'future_date'

export const BASE_CP_DATA_ELEMENT_PATH = 'additional_information'

export interface PropertyCommon {
	id: string
	parentId: string | null
	title?: string | null
	label?: string | null
	modelValue: string
	fullModelPath: string
	description?: string | null
	required?: boolean
	valid?: boolean
}

export type NullProperty = PropertyCommon & {
	type: null
}

export type StringProperty = PropertyCommon & {
	type: 'string'
	minLength?: number | null | string
	maxLength?: number | null | string
	format?: string | null
	identifier?: string | null
}

/**
 * This is a subtype of string
 */
export type DateProperty = StringProperty & {
	format: DateFormatTypes
	identifier: 'date'
}

/**
 * This is a subtype of string
 */
export type PhoneProperty = StringProperty & {
	format: 'phone'
	identifier: 'phone'
}

export type BooleanProperty = PropertyCommon & {
	type: 'boolean'
}

export type EnumProperty = PropertyCommon & {
	type: 'enum'
	enum: Array<string>
}

export type NumberProperty = PropertyCommon & {
	type: 'number'
	minimum?: number | string | null
	maximum?: number | string | null
}

export type IntegerProperty = PropertyCommon & {
	type: 'integer'
	minimum?: number | string | null
	maximum?: number | string | null
}

export type ArrayProperty = PropertyCommon & {
	type: 'array'
	items: { type: 'string' }
}

export type GroupProperty = PropertyCommon & {
	type: 'object'
	properties: Property[]
}

export type Property =
	| GroupProperty
	| IntegerProperty
	| NumberProperty
	| StringProperty
	| BooleanProperty
	| DateProperty
	| PhoneProperty
	| ArrayProperty
	| EnumProperty
	| NullProperty

// Inference functions
export const isNullProperty = (property: Property): property is NullProperty =>
	property.type === null
export const isGroupProperty = (property: Property): property is GroupProperty =>
	property.type === 'object'
export const isStringProperty = (property: Property): property is StringProperty =>
	property.type === 'string' && !property.format
export const isIntegerProperty = (property: Property): property is IntegerProperty =>
	property.type === 'integer'
export const isNumberProperty = (property: Property): property is NumberProperty =>
	property.type === 'number'
export const isBooleanProperty = (property: Property): property is BooleanProperty =>
	property.type === 'boolean'
export const isDateProperty = (property: Property): property is DateProperty => {
	const dateTypes = [
		'date',
		'past_date',
		'past_or_present_date',
		'future_or_present_date',
		'future_date',
	]
	return property.type === 'string' && dateTypes.includes(property.format ?? '')
}
export const isPhoneProperty = (property: Property): property is PhoneProperty =>
	property.type === 'string' && property.format === 'phone'
export const isArrayProperty = (property: Property): property is ArrayProperty =>
	property.type === 'array'
export const isEnumProperty = (property: Property): property is EnumProperty =>
	property.type === 'enum'

/**
 * When using JSON Schema to represent the different property types,
 * DateProperty and PhoneProperty are encoded as "string". To distinguish
 * between these property types, we extend the property type union with
 * additional string literal types for each of these string-like types.
 */
export type StringLikeType = 'date' | 'phone'

export type UniquePropertyType = Property['type'] | StringLikeType

export function getUniquePropertyType(property: Property): UniquePropertyType {
	if (isDateProperty(property)) {
		return 'date'
	} else if (isPhoneProperty(property)) {
		return 'phone'
	} else {
		return property.type
	}
}
