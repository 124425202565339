import { createApp } from 'vue'
import App from '@/components/App'
import router from '@/modules/router/router'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { applyDefaultFontFamily } from '@myndshft/fonts'

import {
	faTrash,
	faCopy,
	faSearch,
	faFilter,
	faChevronUp,
	faChevronDown,
	faPlus,
	faPlusCircle,
	faTimes,
	faInfoCircle,
	faSortAlphaDown,
	faSortAlphaDownAlt,
	faSortNumericDown,
	faSortNumericDownAlt,
	faPenSquare,
	faSave,
} from '@fortawesome/free-solid-svg-icons'

library.add(
	faTrash,
	faCopy,
	faSearch,
	faFilter,
	faChevronUp,
	faChevronDown,
	faSortAlphaDown,
	faSortAlphaDownAlt,
	faSortNumericDown,
	faSortNumericDownAlt,
	faPlus,
	faPlusCircle,
	faTimes,
	faInfoCircle,
	faPenSquare,
	faSave
)
const app = createApp(App)
app.use(router).component('fa-icon', FontAwesomeIcon).mount('#app')
applyDefaultFontFamily()
