import { State } from './state'
import { FormMetadata } from '@/models/form/metadata'
import { FormsListColumnSort } from '@/models/form/sorts'

function setForms(state: State, forms: FormMetadata[]) {
	state.forms = forms
	return state
}

function setSearchText(state: State, searchText: string) {
	state.searchText = searchText
	return state
}

function setSortField(state: State, sort: FormsListColumnSort) {
	state.sortField = sort
	return state
}

function setIsLeftEnabled(state: State, value: boolean) {
	state.isLeftEnabled = value
	return state
}

export default {
	setForms,
	setSearchText,
	setSortField,
	setIsLeftEnabled,
}
