import * as t from 'io-ts'

import { FormDefinitionMetadata, emptyFormDefinitionMetadata } from './metadata'
import { FormRegistry } from './field/field'

export const FormDefinition = t.type({
	fields: FormRegistry,
	metadata: FormDefinitionMetadata,
})

export type FormDefinition = t.TypeOf<typeof FormDefinition>

export const emptyFormDefinition: FormDefinition = {
	fields: {},
	metadata: emptyFormDefinitionMetadata,
}
