import { Iso, Lens } from 'monocle-ts'
import { ServerFormDefinitionMetadata } from './metadata.server'
import { FormDefinitionMetadata } from './metadata'

export const formDefinitionMetadataIso = new Iso<
	ServerFormDefinitionMetadata,
	FormDefinitionMetadata
>(
	(metadata) => ({ hasDiscoveredFields: metadata.has_discovered_fields ?? false }),
	(metadata) => ({ has_discovered_fields: metadata.hasDiscoveredFields })
)

export const hasDiscoveredFields = Lens.fromProp<FormDefinitionMetadata>()('hasDiscoveredFields')
