import { DateFromISOString } from 'io-ts-types/lib/DateFromISOString'
import { UUID } from 'io-ts-types/lib/UUID'
import * as t from 'io-ts'

import { ServerFormDefinition } from './definition'
import { ServerAdditionalInformation } from './additional-information'

export const ServerForm = t.intersection([
	t.type({
		id: UUID,
		name: t.string,
		num_pages: t.number,
	}),
	t.partial({
		definition: t.union([ServerFormDefinition, t.null]),
		last_modified: t.union([DateFromISOString, t.null]),
		last_modified_by: t.union([t.string, t.null]),
		additional_information: t.union([ServerAdditionalInformation, t.null]),
		form_type: t.union([t.string, t.null]),
		form_status: t.union([t.string, t.null]),
	}),
])

export type ServerForm = t.TypeOf<typeof ServerForm>
