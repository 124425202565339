import { UUID } from 'io-ts-types/lib/UUID'
import { date } from 'io-ts-types/lib/date'
import { option } from 'io-ts-types/lib/option'
import * as t from 'io-ts'

import { FormDefinition } from './definition'
import { AdditionalInformation } from './additional-information'

export enum FORM_STATUS {
	DRAFT = 'DRAFT',
	PUBLISHED = 'PUBLISHED',
}

export enum FormType {
	PORTAL = 'PORTAL',
	FAX = 'FAX',
	MANUAL = 'MANUAL',
}

export const Form = t.type({
	id: UUID,
	name: t.string,
	pageCount: t.number,
	formType: option(t.string),
	formStatus: option(t.string),
	definition: FormDefinition,
	updatedBy: option(t.string),
	lastModified: option(date),
	additionalInformation: AdditionalInformation,
})

export type Form = t.TypeOf<typeof Form>

export type FormDetails = {
	name: string
	type: string | null
	status: string | null
}
