import { UUID } from 'io-ts-types/lib/UUID'
import * as t from 'io-ts'

export const ServerDiscoveredNameMetadata = t.partial({
	discovered_fully_qualified_name: t.union([t.string, t.null]),
})

export type ServerDiscoveredNameMetadata = t.TypeOf<typeof ServerDiscoveredNameMetadata>

export const ServerNameMetadata = t.partial({
	name: t.union([t.string, t.null]),
})

export type ServerNameMetadata = t.TypeOf<typeof ServerNameMetadata>

export const ServerIdMetadata = t.partial({
	id: t.union([UUID, t.null]),
})

export type ServerIdMetadata = t.TypeOf<typeof ServerIdMetadata>
