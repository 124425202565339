import { flow, pipe } from 'fp-ts/lib/function'
import * as A from 'fp-ts/lib/Array'
import * as O from 'fp-ts/lib/Option'
import { Iso, Lens } from 'monocle-ts'

import { ServerCheckboxField } from './checkbox.server'
import { CheckboxField } from './checkbox'

import { discoveredNameMetadataIso, nameMetadataIso } from '../../metadata'
import { requiredConstraintIso } from '../../constraints'
import { dimensionalTraitIso } from '../../traits/dimensional'
import { firstClassTraitIso } from '../../traits/first-class'
import { paginatedTraitIso } from '../../traits/paginated'
import { positionalTraitIso } from '../../traits/positional'
import { unmappableTraitIso } from '../../traits/unmappable'
import { elementRefIso } from '../../element'

export const constraints = {
	required: Lens.fromPath<CheckboxField>()(['constraints', 'required']),
}

export const metadata = {
	discoveredName: Lens.fromPath<CheckboxField>()(['metadata', 'discoveredName']),
	name: Lens.fromPath<CheckboxField>()(['metadata', 'name']),
}

export const element = Lens.fromProp<CheckboxField>()('element')

export const conditionalId = Lens.fromProp<CheckboxField>()('conditionalId')

export const conditionalValue = Lens.fromProp<CheckboxField>()('conditionalValue')

const defaultNameToDiscoveredName = (field: CheckboxField) => {
	const discoveredName = metadata.discoveredName.get(field)
	const name = metadata.name.get(field)
	return metadata.name.set(O.alt(() => discoveredName)(name))(field)
}

export const checkboxFieldIso = new Iso<ServerCheckboxField, CheckboxField>(
	flow(
		(field) => ({
			type: field.type,
			conditionalId: (field.conditional_value_id ?? []).map(O.fromNullable),
			conditionalValue: field.conditional_value,
			element: (field.element ?? []).map(elementRefIso.get),
			constraints: {
				...field.constraints, // preserve unused constraints
				...requiredConstraintIso.get({ ...field.constraints }),
			},
			metadata: {
				...field.metadata, // preserve unused metadata
				...discoveredNameMetadataIso.get({ ...field.metadata }),
				...nameMetadataIso.get({ ...field.metadata }),
			},
			...dimensionalTraitIso.get(field),
			...firstClassTraitIso.get(field),
			...paginatedTraitIso.get(field),
			...positionalTraitIso(field.size.y).get(field),
			...unmappableTraitIso.get(field),
		}),
		defaultNameToDiscoveredName
	),
	(field) => {
		const { discoveredName, name, ...unusedMetadata } = field.metadata
		const { required, ...unusedConstraints } = field.constraints

		return {
			type: field.type,
			conditional_value_id: A.compact(field.conditionalId),
			conditional_value: field.conditionalValue,
			element: pipe(field.element, A.compact, A.map(O.some), A.map(elementRefIso.reverseGet)),
			constraints: {
				...unusedConstraints,
				...requiredConstraintIso.reverseGet(field.constraints),
			},
			metadata: {
				...unusedMetadata,
				...discoveredNameMetadataIso.reverseGet(field.metadata),
				...nameMetadataIso.reverseGet(field.metadata),
			},
			...dimensionalTraitIso.reverseGet(field),
			...firstClassTraitIso.reverseGet(field),
			...paginatedTraitIso.reverseGet(field),
			...positionalTraitIso(field.dimensions.height).reverseGet(field),
			...unmappableTraitIso.reverseGet(field),
		}
	}
)
