import * as O from 'fp-ts/lib/Option'
import { UUID } from 'io-ts-types/lib/UUID'
import * as t from 'io-ts'

import { DiscoveredNameMetadata, NameMetadata } from '../../metadata'
import { RequiredConstraint } from '../../constraints'
import { FormFieldPosition } from '../../position'
import { FormFieldDimensions } from '../../dimensions'
import { DimensionalTrait } from '../../traits/dimensional'
import { FirstClassTrait } from '../../traits/first-class'
import { PaginatedTrait } from '../../traits/paginated'
import { PositionalTrait } from '../../traits/positional'
import { SingleMappableTrait } from '../../traits/single-mappable'
import { UnmappableTrait } from '../../traits/unmappable'
import { TextField } from '@/models/form/definition/field/variants/text'
import { PhoneNumberField } from '@/models/form/definition/field/variants/phone'
import { Lens } from 'monocle-ts'

// prettier-ignore
export const DateField = t.intersection([
	t.type({
		type: t.literal('DATE_FIELD'),
		format: t.string,
		constraints: RequiredConstraint,
		metadata: t.intersection([
			DiscoveredNameMetadata,
			NameMetadata
		]),
	}),
	t.intersection([
		DimensionalTrait,
		FirstClassTrait,
		PaginatedTrait,
		PositionalTrait,
	]),
	t.intersection([
		SingleMappableTrait,
		UnmappableTrait,
	])
])

export const dateFieldFormat = Lens.fromProp<DateField>()('format')

export type DateField = t.TypeOf<typeof DateField>

export const createDateField = (
	id: UUID,
	name: O.Option<string>,
	page: number,
	position: FormFieldPosition,
	dimensions: FormFieldDimensions
): DateField => ({
	id,
	type: 'DATE_FIELD',
	mappable: true,
	element: O.none,
	format: 'MM/dd/yyyy',
	constraints: {
		required: false,
	},
	metadata: {
		discoveredName: O.none,
		name,
	},
	page,
	position,
	dimensions,
})

export function convertToDateField(field: DateField | TextField | PhoneNumberField): DateField {
	return field.type === 'DATE_FIELD'
		? field
		: createDateField(field.id, field.metadata.name, field.page, field.position, field.dimensions)
}

export const isUnmappedDateField = (field: DateField) => O.isNone(field.element)
