import { Iso } from 'monocle-ts'

import { ServerMappableTrait } from './mappable.server'
import { MappableTrait } from './mappable'
import { elementRefIso } from '../../element'
import { Lens } from 'monocle-ts'

export const element = Lens.fromProp<MappableTrait>()('element')

export const mappableTraitIso = new Iso<ServerMappableTrait, MappableTrait>(
	(trait) => ({ element: (trait.element || []).map(elementRefIso.get) }),
	(trait) => ({ element: trait.element.map(elementRefIso.reverseGet).filter(Boolean) })
)
