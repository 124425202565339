import { State } from './state'
import * as O from 'fp-ts/lib/Option'

function isLoggedIn(state: State) {
	return O.isSome(state.user)
}

function isRefreshing(state: State) {
	return O.isSome(state.refresh)
}

function isInitial(state: State) {
	return state.initial
}

export default {
	isLoggedIn,
	isRefreshing,
	isInitial,
}
