import { FormMetadata } from '@/models/form/metadata'
import { FormsListColumnSort, SortOption } from '@/models/form/sorts'

const state = () => ({
	forms: [] as FormMetadata[],
	searchText: '',
	sortField: { name: 'name', option: SortOption.ASCENDING } as FormsListColumnSort,
	upLoading: false, // forms are currently being uploaded
	isLeftEnabled: false, //left pane for filters
})

export type State = ReturnType<typeof state>

export default state
