import { UUID } from 'io-ts-types/lib/UUID'
import * as t from 'io-ts'

import { ServerDiscoveredNameMetadata, ServerNameMetadata, ServerIdMetadata } from '../../metadata'
import { ServerRequiredConstraint } from '../../constraints'
import { ServerDimensionalTrait } from '../../traits/dimensional'
import { ServerFirstClassTrait } from '../../traits/first-class'
import { ServerMappableTrait } from '../../traits/mappable'
import { ServerPaginatedTrait } from '../../traits/paginated'
import { ServerPositionalTrait } from '../../traits/positional'
import { ServerUnmappableTrait } from '../../traits/unmappable'

export const ServerRadioConditional = t.union([
	t.partial({ conditional_id: t.union([t.array(UUID), t.null]) }),
	t.partial({ conditional_value: t.union([t.boolean, t.null]) }),
])

export type ServerRadioConditional = t.TypeOf<typeof ServerRadioConditional>

// prettier-ignore
export const ServerRadioOption = t.intersection([
	t.type({ value: t.string }),
	t.partial({
		metadata: t.union([
			t.intersection([
				ServerNameMetadata,
				ServerIdMetadata,
			]),
			t.null,
		])
	}),
	t.intersection([
		ServerRadioConditional,
		ServerDimensionalTrait,
		ServerPaginatedTrait,
		ServerPositionalTrait,
	])
])

export type ServerRadioOption = t.TypeOf<typeof ServerRadioOption>

// prettier-ignore
export const ServerRadioField = t.intersection([
	t.type({
		type: t.literal('RADIO_BUTTON'),
		constraints: ServerRequiredConstraint,
		options: t.array(ServerRadioOption),
	}),
	t.partial({
		metadata: t.intersection([
			ServerDiscoveredNameMetadata,
			ServerNameMetadata,
		]),
	}),
	t.intersection([
		ServerFirstClassTrait,
		ServerMappableTrait,
		ServerUnmappableTrait,
	]),
])

export type ServerRadioField = t.TypeOf<typeof ServerRadioField>
