import { identity } from 'fp-ts/lib/function'
import { Eq } from 'fp-ts/lib/Eq'
import * as A from 'fp-ts/lib/Array'
import * as O from 'fp-ts/lib/Option'

import { UUID } from 'io-ts-types/lib/UUID'
import * as t from 'io-ts'

import { Lens, Prism, fromTraversable } from 'monocle-ts'

export const DataElementBase = t.intersection([
	t.type({
		id: t.string,
		name: t.string,
	}),
	t.partial({
		parent: UUID,
	}),
])

export type DataElementBase = t.TypeOf<typeof DataElementBase>

export const QualifiedDataElementBase = t.type({
	id: t.string,
	name: t.string,
})

export type QualifiedDataElementBase = t.TypeOf<typeof QualifiedDataElementBase>

export const NumberElement = t.type({ type: t.literal('NUMBER') })
export const MoneyElement = t.type({ type: t.literal('MONEY') })
export const StringElement = t.type({ type: t.literal('STRING') })
export const BooleanElement = t.type({ type: t.literal('BOOLEAN') })
export const DateElement = t.type({ type: t.literal('DATE') })
export const TimeElement = t.type({ type: t.literal('TIME') })
export const ListElement = t.type({ type: t.literal('LIST') })
export const ObjectElement = t.type({ type: t.literal('OBJECT') })
export const MapElement = t.type({ type: t.literal('MAP') })
export const EnumValue = t.type({ id: t.string, value: t.string })
export const EnumElement = t.type({ type: t.literal('ENUM'), values: t.array(EnumValue) })

export type NumberElement = t.TypeOf<typeof NumberElement>
export type MoneyElement = t.TypeOf<typeof MoneyElement>
export type StringElement = t.TypeOf<typeof StringElement>
export type BooleanElement = t.TypeOf<typeof BooleanElement>
export type DateElement = t.TypeOf<typeof DateElement>
export type TimeElement = t.TypeOf<typeof TimeElement>
export type ListElement = t.TypeOf<typeof ListElement>
export type ObjectElement = t.TypeOf<typeof ObjectElement>
export type MapElement = t.TypeOf<typeof MapElement>
export type EnumValue = t.TypeOf<typeof EnumValue>
export type EnumElement = t.TypeOf<typeof EnumElement>

const DataElementSum = t.union([
	NumberElement,
	MoneyElement,
	StringElement,
	BooleanElement,
	DateElement,
	TimeElement,
	ListElement,
	ObjectElement,
	MapElement,
	EnumElement,
])

export const DataElement = t.intersection([DataElementBase, DataElementSum])

export type DataElement = t.TypeOf<typeof DataElement>

export const QualifiedDataElement = t.intersection([QualifiedDataElementBase, DataElementSum])

export type QualifiedDataElement = t.TypeOf<typeof QualifiedDataElement>

export const eqQualifiedDataElement: Eq<QualifiedDataElement> = {
	equals: (x, y) => x.id.toLowerCase() === y.id.toLowerCase(),
}

const dataElementTraversal = fromTraversable(A.array)<DataElement | QualifiedDataElement>()

const dataElementLenses = Lens.fromProp<DataElement | QualifiedDataElement>()
const dataElementId = dataElementLenses('id')

const enumElement = new Prism<
	DataElement | QualifiedDataElement,
	(DataElementBase | QualifiedDataElementBase) & EnumElement
>((s) => (s.type === 'ENUM' ? O.some(s) : O.none), identity)

const enumLenses = Lens.fromProp<(DataElementBase | QualifiedDataElementBase) & EnumElement>()
const enumValues = enumLenses('values')

const enumValueLenses = Lens.fromProp<EnumValue>()
const enumValueId = enumValueLenses('id')

const enumValueTraversal = fromTraversable(A.array)<EnumValue>()

export const allElementIds = dataElementTraversal.composeLens(dataElementId)

export const allEnumValueIds = dataElementTraversal
	.composePrism(enumElement)
	.composeLens(enumValues)
	.composeTraversal(enumValueTraversal)
	.composeLens(enumValueId)
