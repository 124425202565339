import * as t from 'io-ts'

export type ServerElementRef =
	| {
			element: string
			then: ServerElementRef
	  }
	| null
	| undefined

type ServerElementRefOutput =
	| {
			element: string
			then: ServerElementRef
	  }
	| null
	| undefined

export const ServerElementRef: t.RecursiveType<t.Type<ServerElementRef, ServerElementRefOutput>> =
	t.recursion('ServerElementRef', () =>
		t.union([
			t.type({
				element: t.string,
				then: ServerElementRef,
			}),
			t.null,
			t.undefined,
		])
	)
