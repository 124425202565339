import { Machine, MachineConfig, assign, interpret } from 'xstate'
import { Context, Schema, Event } from './types'

const config: MachineConfig<Context, Schema, Event> = {
	id: 'DragAndDropMachine',
	initial: 'idle',
	context: {
		files: [],
	},
	states: {
		idle: {
			on: {
				DRAG_OVER: 'dragging',
				ATTACH: {
					target: 'validating',
					actions: [
						assign({
							files: (_, e) => e.files,
						}),
					],
				},
			},
		},
		dragging: {
			on: {
				DRAG_LEAVE: 'idle',
				DROP: {
					target: 'validating',
					actions: [
						assign({
							files: (_, e) => e.files,
						}),
					],
				},
			},
		},
		validating: {
			always: [
				{
					target: 'error.noFilesFound',
					cond: 'noFilesFound',
				},
				{
					target: 'error.tooManyFiles',
					cond: 'tooManyFiles',
				},
				{
					target: 'error.invalidMIMEType',
					cond: 'invalidMIMEType',
				},
				{
					target: 'validated',
				},
			],
		},
		validated: {
			on: {
				RESET: 'idle',
			},
		},
		error: {
			states: {
				noFilesFound: {},
				tooManyFiles: {},
				invalidMIMEType: {},
			},

			on: {
				CONFIRM_ERROR: 'idle',
				RESET: 'idle',
			},
		},
	},
}

const guards = {
	noFilesFound(c: Context) {
		return c.files.length === 0
	},
	tooManyFiles(c: Context) {
		return c.files.length > 1
	},
	invalidMIMEType(c: Context) {
		// fallthrough
		return c.files[0].type !== 'application/pdf'
	},
}

export const dragAndDropMachine = Machine<Context, Schema, Event>(config, { guards })
export const dragAndDropService = interpret(dragAndDropMachine).start()
