import {
	startTokenExpirationCheck as startCheckingForExpiration,
	clearExpirationCheckInterval,
} from '@/modules/auth'
import router from '@/modules/router'
import { toast } from '@/components/Toast'
import * as O from 'fp-ts/lib/Option'
import { pipe } from 'fp-ts/lib/function'
import { GoogleUser } from '@/models/auth/user'
import * as E from 'fp-ts/Either'
import LogRocket from 'logrocket'
import { useMyndmapApiV2 } from '@/modules/api/hook'
import { State } from '../store/state'

// https://developers.google.com/identity/gsi/web/reference/js-reference#CredentialResponse
export interface CredentialResponse {
	credential: string
	select_by:
		| 'auto'
		| 'user'
		| 'user_1tap'
		| 'user_2tap'
		| 'btn'
		| 'btn_confirm'
		| 'btn_add_session'
		| 'btn_confirm_add_session'
}

interface JWT {
	header: {
		alg: string
		kid: string
		typ: string
	}

	payload: {
		iss: string
		nbf: number
		aud: string
		sub: string
		hd?: string
		email: string
		email_verified: boolean
		azp: string
		name: string
		picture?: string
		given_name: string
		family_name: string
		iat: number
		exp: number
		jti: string
	}

	signature: string
}

function decodeJWT(jwt: string): JWT {
	const parts = jwt.split('.')
	if (parts.length !== 3) {
		throw new Error('invalid JWT')
	}

	const header = JSON.parse(atob(parts[0]))
	const payload = JSON.parse(atob(parts[1]))

	return {
		header: header,
		payload: payload,
		signature: parts[2],
	}
}

export default {
	login: async (
		{ state, commit }: { state: State; commit: any },
		credentials: CredentialResponse
	) => {
		if (credentials) {
			const jwt = decodeJWT(credentials.credential)
			const user = jwt.payload

			pipe(
				GoogleUser.decode({
					email: user.email,
					expiresAt: user.exp,
					id: user.email,
					imageUrl: user.picture,
					name: user.name,
					tokenId: credentials.credential,
				}),
				E.fold(
					() => toast('Unexpected response received'),
					(user) => {
						commit('setUser', O.some(user))
						if (state.initial) {
							commit('setInitial', false)
							LogRocket.identify(user.email, {
								name: user.name,
								email: user.email,
							})
							toast(`Welcome back, ${user?.name}!`)
							startCheckingForExpiration()
						}
					}
				)
			)

			const { login } = useMyndmapApiV2()
			const loginResponse = await login({ token: credentials.credential })
			return loginResponse?.valid || false
		}
	},
	checkActive: async ({ commit }: { commit: any }) => {
		const { checkActive } = useMyndmapApiV2()
		const checkActiveResponse = await checkActive()
		const valid = checkActiveResponse?.valid || false
		if (!valid) {
			commit('setUser', O.none)
		}
		return valid
	},
	logout: async ({ commit }: { commit: any }) => {
		const { logout } = useMyndmapApiV2()
		await logout()
		commit('logout')
		router.push({ name: 'Home' }).finally()
		clearExpirationCheckInterval()
	},
}
