import { computed, ref, watch } from 'vue'
import { useConfigStore } from '@/modules/mynd-config'
import { useAuthStore } from '@/modules/auth/store'

import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
import { EnvironmentConfig } from '@/models/environment'
import router from '@/modules/router/router'

import { isValidGoogleUser, remainingMinutesBeforeExpiration } from '@/modules/auth/validation'
import { GoogleUser } from '@/models/auth/user'

const EXPIRATION_CHECK_DELAY_MILLISECONDS = 30000

let expirationInterval: number | null = null

/**
 * Composition hook that integrates Google Sign-In into the Login page by
 * injecting a special <div> tag and a <script> tag into the HTML document.
 * The <div> tag specifies the client ID associated with the Mynd Control
 * Google OAuth application. The <script> tag loads the Google Platform Library.
 *
 * @see {@link https://developers.google.com/identity/gsi/web/guides/use-one-tap-js-api}
 * @see {@link https://developers.google.com/identity/gsi/web/reference/js-reference}
 */
export const useGoogleSignIn = () => {
	const configStore = useConfigStore()
	const isGapiReady = ref(false)
	const config = computed(() => configStore.getters['getConfig'])
	const auth = useAuthStore()

	watch(config, (config: EnvironmentConfig) => {
		if (config) {
			window?.google?.accounts?.id?.initialize({
				client_id: `${config.googleClientId}.apps.googleusercontent.com`,
				auto_select: true,
				use_fedcm_for_prompt: true,
				callback: (user: any) => {
					const path: string = (router?.currentRoute?.value?.query?.returnUrl as string) || '/files'
					auth.dispatch('login', user).then(async () => router.push({ path }))
				},
			})
			window?.google?.accounts?.id?.prompt()
			isGapiReady.value = true
		} else {
			router.push({ name: 'Home' }).finally()
		}
	})
	return {
		isGapiReady,
	}
}

export const clearExpirationCheckInterval = () => {
	if (expirationInterval) {
		clearInterval(expirationInterval)
		expirationInterval = null
	}
}

export const tokenExpirationCheckHandler = (configStore: any, authStore: any, google?: any) => {
	const user = pipe(
		authStore.state.user,
		O.getOrElse(() => ({} as GoogleUser))
	)
	if (isValidGoogleUser(user)) {
		const minutesRemaining = remainingMinutesBeforeExpiration(user)
		const minutesWhenToReinitialize = configStore.state.config.reInitializeWhenLessThanMinutes
		if (
			minutesRemaining < minutesWhenToReinitialize &&
			!!configStore?.state?.config?.googleClientId
		) {
			google?.initialize({
				client_id: `${configStore.state.config.googleClientId}.apps.googleusercontent.com`,
				auto_select: true,
				use_fedcm_for_prompt: true,
				callback: (user: any) => {
					authStore.dispatch('login', user)
				},
			})
			google?.prompt()
		}
	} else {
		authStore.dispatch('logout').finally()
	}
}

export const startTokenExpirationCheck = () => {
	const configStore = useConfigStore()
	const authStore = useAuthStore()

	expirationInterval = setInterval(
		() => tokenExpirationCheckHandler(configStore, authStore, window?.google?.accounts?.id),
		EXPIRATION_CHECK_DELAY_MILLISECONDS
	)
}
