import * as O from 'fp-ts/lib/Option'
import { Iso } from 'monocle-ts'
import { ServerAdditionalInformation } from './additional-information.server'
import { AdditionalInformation } from './additional-information'

export const additionalInformationIso = new Iso<ServerAdditionalInformation, AdditionalInformation>(
	(properties: ServerAdditionalInformation) => ({
		$schema: properties.$schema,
		$id: properties.$id,
		type: properties.type,
		properties: properties.properties,
		title: O.fromNullable(properties.title),
		description: O.fromNullable(properties.description || null),
		required: properties.required ?? [],
	}),
	(properties: AdditionalInformation) => ({
		$schema: properties.$schema,
		$id: properties.$id,
		type: properties.type,
		properties: properties.properties,
		title: O.toNullable(properties.title),
		description: O.toNullable(properties.description || null),
		required: properties.required,
	})
)
