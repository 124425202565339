<template>
	<div class="toast-message">
		<span class="toast-message-text">{{ message }}</span>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
	name: 'ToastMessage',
	props: {
		message: String,
	},
})
</script>

<style lang="scss" scoped>
@import '@/styles/colors.scss';

.toast-message {
	background: $myndshft-gray-200;
	border-radius: 8px;
	padding: 8px 16px;
	margin: 8px auto;
	display: inline-block;
	user-select: none;
}

.toast-message-text {
	margin: 4px;
	font-size: 16px;
	color: white;
}
</style>
