import { Environment, EnvironmentConfig } from '@/models/environment'

const defaultState = (): EnvironmentConfig => ({
	environment: 'development' as Environment,
	apiServer: '',
	googleClientId: '',
	reInitializeWhenLessThanMinutes: 1,
})

export const state = {
	config: defaultState(),
}

export type ConfigState = typeof state
