import * as t from 'io-ts'

export const FormDefinitionMetadata = t.type({
	hasDiscoveredFields: t.boolean,
})

export type FormDefinitionMetadata = t.TypeOf<typeof FormDefinitionMetadata>

export const emptyFormDefinitionMetadata: FormDefinitionMetadata = {
	hasDiscoveredFields: false,
}
