import { Iso } from 'monocle-ts'

import { ServerPositionalTrait } from './positional.server'
import { PositionalTrait } from './positional'
import { formFieldPositionIso } from '../../position'

export const positionalTraitIso = (height: number) =>
	new Iso<ServerPositionalTrait, PositionalTrait>(
		(trait) => ({ position: formFieldPositionIso(height).get(trait.position) }),
		(trait) => ({ position: formFieldPositionIso(height).reverseGet(trait.position) })
	)
