import * as t from 'io-ts'

import { ServerElementRef } from '../../element'

export const ServerMappableTrait = t.type({
	element: t.union([t.array(ServerElementRef), t.null]),
})

export type ServerMappableTrait = {
	element: ServerElementRef[] | null
}
