import { FeaturesState } from './state'
import { FeatureFlags, FeatureFlagKey, FeatureFlagKeys } from '@/modules/flags'

function setFeatureFlags(state: FeaturesState, featureFlags: FeatureFlags) {
	state.featureFlags = featureFlags
}

/**
 * Updates the current feature flags and adds new ones
 * @param state
 * @param featureFlags
 */
function updateFeatureFlags(state: FeaturesState, featureFlags: FeatureFlags) {
	if (JSON.stringify({ ...state.featureFlags }) !== JSON.stringify({ ...featureFlags })) {
		state.featureFlags = { ...state.featureFlags, ...featureFlags }
	}
}

function setFeatureFlagKeys(state: FeaturesState, featureFlagKeys: FeatureFlagKeys) {
	state.featureFlagKeys = featureFlagKeys
}

function addUniqueFeatureFlagKey(state: FeaturesState, featureFlagKey: FeatureFlagKey) {
	if (!state.featureFlagKeys.includes(featureFlagKey)) {
		state.featureFlagKeys = [...state.featureFlagKeys, featureFlagKey]
		state.featureFlags = { ...state.featureFlags, ...{ [featureFlagKey]: false } }
	}
}

export const mutations = {
	setFeatureFlags,
	updateFeatureFlags,
	setFeatureFlagKeys,
	addUniqueFeatureFlagKey,
}
