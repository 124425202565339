import * as t from 'io-ts'

import { ServerDiscoveredNameMetadata, ServerNameMetadata } from '../../metadata'
import { ServerRequiredConstraint } from '../../constraints'
import { ServerDimensionalTrait } from '../../traits/dimensional'
import { ServerFirstClassTrait } from '../../traits/first-class'
import { ServerPaginatedTrait } from '../../traits/paginated'
import { ServerPositionalTrait } from '../../traits/positional'
import { ServerSingleMappableTrait } from '../../traits/single-mappable'
import { ServerUnmappableTrait } from '../../traits/unmappable'

// prettier-ignore
export const ServerDateField = t.intersection([
	t.type({
		type: t.literal('DATE_FIELD'),
	}),
	t.partial({
		format: t.union([t.string, t.null]),
		constraints: ServerRequiredConstraint,
		metadata: t.intersection([
			ServerDiscoveredNameMetadata,
			ServerNameMetadata
		]),
	}),
	t.intersection([
		ServerDimensionalTrait,
		ServerFirstClassTrait,
		ServerPaginatedTrait,
		ServerPositionalTrait,
	]),
	t.intersection([
		ServerSingleMappableTrait,
		ServerUnmappableTrait,
	])
])

export type ServerDateField = t.TypeOf<typeof ServerDateField>
