import { UUID } from 'io-ts-types/lib/UUID'
import { option } from 'io-ts-types/lib/option'
import * as t from 'io-ts'

export const DiscoveredNameMetadata = t.type({
	discoveredName: option(t.string),
})

export type DiscoveredNameMetadata = t.TypeOf<typeof DiscoveredNameMetadata>

export const NameMetadata = t.type({
	name: option(t.string),
})

export type NameMetadata = t.TypeOf<typeof NameMetadata>

export const IdMetadata = t.type({
	id: UUID,
})

export type IdMetadata = t.TypeOf<typeof IdMetadata>
