import { flow, pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
import { Iso, Lens } from 'monocle-ts'

import { ServerPhoneNumberField } from './phone.server'
import { PhoneNumberField } from './phone'
import { ServerPhoneNumberFormat, PhoneNumberFormat } from './format'

import { discoveredNameMetadataIso, nameMetadataIso } from '../../metadata'
import { requiredConstraintIso } from '../../constraints'
import { dimensionalTraitIso } from '../../traits/dimensional'
import { firstClassTraitIso } from '../../traits/first-class'
import { paginatedTraitIso } from '../../traits/paginated'
import { positionalTraitIso } from '../../traits/positional'
import { singleMappableTraitIso } from '../../traits/single-mappable'
import { unmappableTraitIso } from '../../traits/unmappable'

export const format = Lens.fromProp<PhoneNumberField>()('format')

export const element = Lens.fromProp<PhoneNumberField>()('element')

export const constraints = {
	required: Lens.fromPath<PhoneNumberField>()(['constraints', 'required']),
}

export const metadata = {
	discoveredName: Lens.fromPath<PhoneNumberField>()(['metadata', 'discoveredName']),
	name: Lens.fromPath<PhoneNumberField>()(['metadata', 'name']),
}

const defaultNameToDiscoveredName = (field: PhoneNumberField) => {
	const discoveredName = metadata.discoveredName.get(field)
	const name = metadata.name.get(field)
	return metadata.name.set(O.alt(() => discoveredName)(name))(field)
}

const phoneNumberFormatIso = new Iso<ServerPhoneNumberFormat, PhoneNumberFormat>(
	(format) => format.toUpperCase() as PhoneNumberFormat,
	(format) => format.toLowerCase() as ServerPhoneNumberFormat
)

export const phoneNumberFieldIso = new Iso<ServerPhoneNumberField, PhoneNumberField>(
	flow(
		(field) => ({
			type: field.type,
			format: pipe(field.section, O.fromNullable, O.map(phoneNumberFormatIso.get)),
			constraints: {
				...field.constraints, // preserve unused constraints
				...requiredConstraintIso.get({ ...field.constraints }),
			},
			metadata: {
				...field.metadata, // preserve unused metadata
				...discoveredNameMetadataIso.get({ ...field.metadata }),
				...nameMetadataIso.get({ ...field.metadata }),
			},
			...dimensionalTraitIso.get(field),
			...firstClassTraitIso.get(field),
			...paginatedTraitIso.get(field),
			...positionalTraitIso(field.size.y).get(field),
			...singleMappableTraitIso.get(field),
			...unmappableTraitIso.get(field),
		}),
		defaultNameToDiscoveredName
	),
	(field) => {
		const { discoveredName, name, ...unusedMetadata } = field.metadata
		const { required, ...unusedConstraints } = field.constraints

		return {
			type: field.type,
			section: pipe(field.format, O.map(phoneNumberFormatIso.reverseGet), O.toNullable),
			constraints: {
				...unusedConstraints,
				...requiredConstraintIso.reverseGet(field.constraints),
			},
			metadata: {
				...unusedMetadata,
				...discoveredNameMetadataIso.reverseGet(field.metadata),
				...nameMetadataIso.reverseGet(field.metadata),
			},
			...dimensionalTraitIso.reverseGet(field),
			...firstClassTraitIso.reverseGet(field),
			...paginatedTraitIso.reverseGet(field),
			...positionalTraitIso(field.dimensions.height).reverseGet(field),
			...singleMappableTraitIso.reverseGet(field),
			...unmappableTraitIso.reverseGet(field),
		}
	}
)
