import { GoogleUser } from '@/models/auth/user'

/**
 * This currently checks the object exists and is not expired
 * @param userObject
 */
export const isValidGoogleUser = (userObject?: GoogleUser | null): boolean => {
	if (!userObject || !userObject?.expiresAt) {
		return false
	}

	const expireDate = new Date(userObject.expiresAt * 1000).getTime()
	const currentDate = new Date().getTime()
	return expireDate > currentDate
}

export const remainingMinutesBeforeExpiration = (userObject?: GoogleUser | null): number => {
	if (!userObject || !userObject?.expiresAt) {
		return 0
	}

	const expirationDateTime = new Date(userObject.expiresAt * 1000).getTime()
	const currentDateAndTime = new Date().getTime()

	// Rounded down since its for expiration
	return Math.floor(
		new Date(Math.abs(currentDateAndTime - expirationDateTime)).getTime() / 1000 / 60
	)
}
