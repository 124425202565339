import * as A from 'fp-ts/lib/Array'
import * as M from 'fp-ts/lib/Monoid'

/**
 * Helper function that checks that each value of `fields` is in the closed
 * interval, [0, 1]. The components of the size and position of a field are
 * normalized to the unit interval for two reasons:
 *
 *   1. It makes them independent of the resolution of a rasterized form page.
 *      They can be scaled up or down as necessary, and the form model would not
 *      have to change at all.
 *
 *   2. Related to the first point, it makes it easy to perform coordinate
 *      transformations between the Cartesian plane and the WebGL plane.
 */
export const inUnitInterval = A.foldMap(M.monoidAll)((x: number) => x >= 0 && x <= 1)
