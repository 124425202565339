import { GoogleUser } from '@/models/auth/user'
import { State } from './state'
import * as O from 'fp-ts/lib/Option'
import { AxiosResponse } from 'axios'

function setUser(state: State, user: O.Option<GoogleUser>) {
	state.user = user
	return state
}

function logout(state: State) {
	state.user = O.none
	return state
}

function setRefresh(state: State, refresh: O.Option<Promise<AxiosResponse<unknown>>>) {
	state.refresh = refresh
	return state
}

function setInitial(state: State, value: boolean) {
	state.initial = value
	return state
}

export default {
	setUser,
	logout,
	setRefresh,
	setInitial,
}
