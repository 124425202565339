import Vuex from 'vuex'
import { state } from './state'
import { mutations } from './mutations'
import { getters } from './getters'

const store = new Vuex.Store({
	state,
	getters,
	mutations,
	actions: {},
})

export const useFlagsStore = () => store
