let messageCount = 0

export interface MessageData {
	id: number
	message: string
	visible: boolean
}

export function nextMessageID() {
	return messageCount++
}
