import * as t from 'io-ts'

import { ServerDiscoveredNameMetadata, ServerNameMetadata } from '../../metadata'
import { ServerRequiredConstraint } from '../../constraints'
import { ServerElementRef } from '../../element'
import { ServerDimensionalTrait } from '../../traits/dimensional'
import { ServerFirstClassTrait } from '../../traits/first-class'
import { ServerPaginatedTrait } from '../../traits/paginated'
import { ServerPositionalTrait } from '../../traits/positional'
import { ServerUnmappableTrait } from '../../traits/unmappable'

// prettier-ignore
export const ServerCheckboxField = t.intersection([
	t.type({
		type: t.literal('CHECK_BOX'),
	}),
	t.partial({
		conditional_value_id: t.union([t.array(t.string), t.null]),
		conditional_value: t.union([t.boolean, t.null]),
		constraints: ServerRequiredConstraint,
		element: t.union([t.array(ServerElementRef), t.null]),
		metadata: t.intersection([
			ServerDiscoveredNameMetadata,
			ServerNameMetadata
		]),
	}),
	t.intersection([
		ServerDimensionalTrait,
		ServerFirstClassTrait,
		ServerPaginatedTrait,
		ServerPositionalTrait,
		ServerUnmappableTrait,
	]),
])

export type ServerCheckboxField = t.TypeOf<typeof ServerCheckboxField>
