import { Iso, Lens } from 'monocle-ts'

import { ServerFormFieldPosition } from './position.server'
import { FormFieldPosition } from './position'

/**
 * Converts between Cartesian coordinates (Apache PDFBox) and y-inverted
 * Cartesian coordinates (WebGL). Assumes that arguments are constrained to the
 * unit interval.
 */
export const formFieldPositionIso = (height: number) =>
	new Iso<ServerFormFieldPosition, FormFieldPosition>(
		(position) => ({
			x: position.x,
			y: 1 - position.y - height,
		}),
		(position) => ({
			x: position.x,
			y: 1 - position.y - height,
		})
	)

export const x = Lens.fromProp<FormFieldPosition>()('x')
export const y = Lens.fromProp<FormFieldPosition>()('y')
