import { AxiosRequestConfig } from 'axios'
import { State } from 'xstate'

import { error as logError } from 'fp-ts/lib/Console'
import * as E from 'fp-ts/lib/Either'
import * as O from 'fp-ts/lib/Option'

import { reporter } from 'io-ts-reporters'
import * as t from 'io-ts'

export interface Context<T = unknown> {
	url: string
	config: AxiosRequestConfig
	runtimeConfig: AxiosRequestConfig
	downloadProgress: O.Option<number>
	uploadProgress: O.Option<number>
	result: O.Option<E.Either<RequestError, T>>
	decoder: t.Decoder<unknown, T>
}

export interface Schema {
	states: {
		idle: {}
		loading: {}
		success: {}
		failure: {
			states: {
				networkError: {}
				validationError: {}
			}
		}
	}
}

export type ConfigEvent = { type: 'SET_CONFIG'; config: AxiosRequestConfig }
export type DispatchEvent = { type: 'DISPATCH' }
export type DownloadProgressEvent = { type: 'DOWNLOAD_UPDATE'; progress: number }
export type UploadProgressEvent = { type: 'UPLOAD_UPDATE'; progress: number }
export type ResolveEvent = { type: 'RESOLVE'; data: unknown }
export type RejectEvent = { type: 'REJECT'; error: RequestError }
export type RetryEvent = { type: 'RETRY' }
export type ResetEvent = { type: 'RESET' }
export type Event =
	| ConfigEvent
	| DispatchEvent
	| DownloadProgressEvent
	| UploadProgressEvent
	| ResolveEvent
	| RejectEvent
	| RetryEvent
	| ResetEvent

/**
 * Represents a valid state of an HTTP request.
 */
export type RequestState<T> = State<Context<T>, Event>

export type NetworkError = { type: 'NETWORK'; context: Error; message: string }
export type ValidationError = { type: 'VALIDATION'; context: t.Errors }
export type RequestError = NetworkError | ValidationError

/**
 * Data constructor for [[RequestError]].
 */
export function networkError(context: Error): RequestError {
	const temp = { ...context } as any
	return { type: 'NETWORK', context, message: temp.response?.data?.message }
}

/**
 * Data constructor for [[RequestError]].
 */
export function validationError(context: t.Errors): RequestError {
	return { type: 'VALIDATION', context }
}

/**
 * An HTTP request can succeed with a result, `T`, or fail with a
 * [[RequestError]].
 */
export type RequestResult<T> = E.Either<RequestError, T>

/**
 * An HTTP request operation may or may not have a result (i.e. during the
 * dispatch of the request).
 */
export type RequestOperation<T> = O.Option<RequestResult<T>>

/**
 * Extracts an error message from [[RequestError]].
 */
export function getRequestErrorMessage(error: RequestError): string {
	switch (error.type) {
		case 'NETWORK':
			return error.message
		case 'VALIDATION':
			logError(reporter(E.left(error.context)))()
			return 'Unexpected response from server'
	}
}
