import { Machine, MachineConfig, interpret, assign } from 'xstate'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { Context, Schema, Event, Coordinates } from './types'

import { UUID } from 'io-ts-types/lib/UUID'
import * as O from 'fp-ts/lib/Option'

const config: MachineConfig<Context, Schema, Event> = {
	id: 'modalMachine',
	initial: 'closed',
	context: {
		id: O.none,
		from: O.none,
	},
	states: {
		closed: {
			entry: assign({
				id: (): O.Option<UUID> => O.none,
				from: (): O.Option<Coordinates> => O.none,
			}),

			on: {
				SHOW: {
					target: 'opening',
					actions: assign({
						id: (_, e) => O.some(e.id),
						from: (_, e) => O.some(e.from),
					}),
				},
			},
		},
		opening: {
			entry: 'disableBodyScroll',

			on: {
				SHOW_TRANSITION_COMPLETE: 'open',
			},
		},
		open: {
			on: {
				HIDE: 'closing',
				OVERLAY: 'overlay',
				FORCE_HIDE: 'closed',
			},
		},
		overlay: {
			on: {
				HIDE: 'closing',
				TRANSITION_TO_OPEN: 'open',
			},
		},
		closing: {
			entry: 'enableBodyScroll',

			on: {
				HIDE_TRANSITION_COMPLETE: 'closed',
			},
		},
	},
}

const actions = {
	disableBodyScroll() {
		disableBodyScroll('body')
	},
	enableBodyScroll() {
		enableBodyScroll('body')
	},
}

export const modalMachine = Machine<Context, Schema, Event>(config, { actions })
export const modalService = interpret(modalMachine).start()
