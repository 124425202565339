import { AxiosResponse } from 'axios'
import { GoogleUser } from '@/models/auth/user'

import * as O from 'fp-ts/lib/Option'

const state = () => ({
	user: O.none as O.Option<GoogleUser>,
	refresh: O.none as O.Option<Promise<AxiosResponse<unknown>>>,
	initial: true,
})

export type State = ReturnType<typeof state>

export default state
