
import { defineComponent, onBeforeUnmount } from 'vue'
import ToastMessage from '@/components/Toast/Message'
import { useMessageQueue, clearMessageQueue } from '@/components/Toast/Display/service'

export default defineComponent({
	name: 'ToastDisplay',
	components: {
		[ToastMessage.name]: ToastMessage,
	},
	props: {
		displayTime: { type: Number, required: true }, // milliseconds
		maxDisplayed: { type: Number, required: true },
	},
	setup(props) {
		onBeforeUnmount(() => clearMessageQueue())
		return useMessageQueue(props.maxDisplayed, props.displayTime)
	},
})
