import * as t from 'io-ts'

interface FullyQualifiedPathBrand {
	readonly FullyQualifiedPath: unique symbol
}

export type FullyQualifiedPath = t.Branded<string, FullyQualifiedPathBrand>

export const FullyQualifiedPath = t.brand(
	t.string,
	(s): s is FullyQualifiedPath => t.array(t.string).is(s.split('.')),
	'FullyQualifiedPath'
)

export const concat = (uuids: string[]) => uuids.join('.') as FullyQualifiedPath

export const split = (list: FullyQualifiedPath) => list.split('.')
