import * as O from 'fp-ts/lib/Option'
import { UUID } from 'io-ts-types/lib/UUID'
import { option } from 'io-ts-types/lib/option'
import * as t from 'io-ts'

import { DiscoveredNameMetadata, NameMetadata } from '../../metadata'
import { RequiredConstraint } from '../../constraints'
import { FormFieldPosition } from '../../position'
import { FormFieldDimensions } from '../../dimensions'
import { DimensionalTrait } from '../../traits/dimensional'
import { FirstClassTrait } from '../../traits/first-class'
import { PaginatedTrait } from '../../traits/paginated'
import { PositionalTrait } from '../../traits/positional'
import { SingleMappableTrait } from '../../traits/single-mappable'
import { UnmappableTrait } from '../../traits/unmappable'
import { PhoneNumberFormat } from './format'
import { TextField } from '@/models/form/definition/field/variants/text'
import { DateField } from '@/models/form/definition/field/variants/date'
import { Lens } from 'monocle-ts'

// prettier-ignore
export const PhoneNumberField = t.intersection([
	t.type({
		type: t.literal('PHONE_NUMBER_FIELD'),
		format: option(PhoneNumberFormat),
		constraints: RequiredConstraint,
		metadata: t.intersection([
			DiscoveredNameMetadata,
			NameMetadata
		]),
	}),
	t.intersection([
		DimensionalTrait,
		FirstClassTrait,
		PaginatedTrait,
		PositionalTrait,
	]),
	t.intersection([
		SingleMappableTrait,
		UnmappableTrait,
	])
])

export const phoneNumberFormat = Lens.fromProp<PhoneNumberField>()('format')

export type PhoneNumberField = t.TypeOf<typeof PhoneNumberField>

export const createPhoneNumberField = (
	id: UUID,
	name: O.Option<string>,
	page: number,
	position: FormFieldPosition,
	dimensions: FormFieldDimensions
): PhoneNumberField => ({
	id,
	type: 'PHONE_NUMBER_FIELD',
	mappable: true,
	element: O.none,
	format: O.some('FULL'),
	constraints: {
		required: false,
	},
	metadata: {
		discoveredName: O.none,
		name,
	},
	page,
	position,
	dimensions,
})

export function convertToPhoneNumberField(
	field: PhoneNumberField | TextField | DateField
): PhoneNumberField {
	return field.type === 'PHONE_NUMBER_FIELD'
		? field
		: createPhoneNumberField(
				field.id,
				field.metadata.name,
				field.page,
				field.position,
				field.dimensions
		  )
}

export const isUnmappedPhoneNumberField = (field: PhoneNumberField) => O.isNone(field.element)
