import { identity } from 'fp-ts/lib/function'
import { Iso } from 'monocle-ts'

import { ServerRequiredConstraint } from './constraints.server'
import { RequiredConstraint } from './constraints'

export const requiredConstraintIso = new Iso<ServerRequiredConstraint, RequiredConstraint>(
	(constraints) => ({ required: constraints.required ?? false }),
	identity
)
