import { computed, ref, Ref, watch } from 'vue'
import { MessageData, nextMessageID } from '@/components/Toast/Message/state'

/**
 * This service is used in order to setup the toast message queue
 * NOTE: timeouts should be cleared through clearMessageQueue when the component that uses this is unmounted.
 */

const queue: Ref<MessageData[] | null> = ref(null)
const timeouts: Ref<number[] | null> = ref(null)

const checkInitialized = () => {
	if (!queue.value) {
		queue.value = []
	}
	if (!timeouts.value) {
		timeouts.value = []
	}
}

export function toast(msg: string) {
	checkInitialized()
	const next = {
		id: nextMessageID(),
		message: msg,
		visible: false,
	}
	queue.value?.push(next)
}

export function useMessageQueue(count: number, displayTime: number) {
	checkInitialized()
	const liveQueue = computed(() => queue?.value?.slice(0, Math.min(count, queue.value.length)))

	watch([liveQueue], ([liveQueue]) => {
		for (const m of liveQueue ?? []) {
			if (!m.visible) {
				m.visible = true
				const timeoutId = setTimeout(() => {
					queue?.value?.shift()
				}, displayTime)
				timeouts.value = timeouts?.value ? [...timeouts.value, timeoutId] : [timeoutId]
			}
		}
	})
	return { liveQueue }
}

export function clearMessageQueue() {
	if (timeouts.value?.length) {
		timeouts.value.forEach((timeout) => clearTimeout(timeout))
		timeouts.value = null
		queue.value = null
	}
}
