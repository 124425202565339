<template>
	<div id="modal-overlay" @mousedown="mouseDown" @mouseup="mouseUp" :class="overlayClasses"></div>
</template>

<script lang="ts">
import { useFormsStore } from '@/modules/forms'
import { defineComponent, computed, ref, onBeforeUnmount } from 'vue'
import { useService } from '@xstate/vue'
import { modalService } from '../machine'
import { dragAndDropService } from '@/components/FormUploadFlow/machine'

export default defineComponent({
	name: 'ModalOverlay',
	setup() {
		const store = useFormsStore()

		const { state, send } = useService(modalService)
		const { send: selectSend } = useService(dragAndDropService)

		const hideModal = () => {
			if (store.state.upLoading) {
				return
			} else {
				send('OVERLAY')
				selectSend('RESET')
			}
		}

		const isDown = ref(false)
		const timer = ref(0)

		const mouseDown = () => {
			isDown.value = true
			timer.value = setTimeout(() => {
				isDown.value = false
			}, 120)
		}

		const mouseUp = () => {
			if (isDown.value === true) {
				hideModal()
				isDown.value = false
			} else {
				return
			}
			clearTimeout(timer.value)
		}

		const isVisible = computed(() => {
			return (
				state.value.matches('opening') ||
				state.value.matches('open') ||
				state.value.matches('overlay')
			)
		})

		const overlayClasses = computed(() => ({
			visible: isVisible.value,
		}))

		onBeforeUnmount(() => clearTimeout(timer.value))

		return {
			hideModal,
			overlayClasses,
			state,
			mouseDown,
			mouseUp,
		}
	},
})
</script>

<style lang="scss" scoped>
#modal-overlay {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 1000;
	pointer-events: none;
}

#modal-overlay.visible {
	pointer-events: all;
	overflow-y: hidden;
}

#modal-overlay::before {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 1000;
	transition: background 500ms ease-out;
}

#modal-overlay.visible:before {
	background: rgba(82, 95, 127, 0.7);
	overflow-y: hidden;
}
</style>
