import { identity } from 'fp-ts/lib/function'
import { pipe } from 'fp-ts/lib/function'
import * as A from 'fp-ts/lib/Array'
import * as O from 'fp-ts/lib/Option'
import { UUID } from 'io-ts-types/lib/UUID'
import { atRecord } from 'monocle-ts/lib/At/Record'
import { Iso, Lens, Optional, Prism } from 'monocle-ts'

import { ServerFormField } from './field.server'
import {
	FormField,
	FormRegistry,
	MappableField,
	VisibleField,
	SingleMappableField,
	UnmappableField,
} from './field'
import { filterRadioOptions } from './filters'

import { TextField, textFieldIso } from './variants/text'
import { CheckboxField, checkboxFieldIso } from './variants/checkbox'
import { RadioGroup, RadioOption, radioFieldIso } from './variants/radio'
import { DateField, dateFieldIso } from './variants/date'
import { phoneNumberFieldIso, PhoneNumberField } from './variants/phone'

export const formFieldIso = new Iso<ServerFormField[], FormField[]>(
	A.chain((field) => {
		switch (field.type) {
			case 'TEXT_FIELD':
				return A.of(textFieldIso.get(field))
			case 'CHECK_BOX':
				return A.of(checkboxFieldIso.get(field))
			case 'RADIO_BUTTON': {
				const { group, options } = radioFieldIso.get(field)
				return A.of<FormField>(group).concat(options)
			}
			case 'DATE_FIELD':
				return A.of(dateFieldIso.get(field))
			case 'PHONE_NUMBER_FIELD':
				return A.of(phoneNumberFieldIso.get(field))
		}
	}),
	(fields) =>
		pipe(
			fields,
			A.chain((field): ServerFormField[] => {
				switch (field.type) {
					case 'TEXT_FIELD':
						return A.of(textFieldIso.reverseGet(field))
					case 'CHECK_BOX':
						return A.of(checkboxFieldIso.reverseGet(field))
					case 'RADIO_GROUP': {
						const group = field
						const options = pipe(
							filterRadioOptions(fields),
							A.filter((o) => o.parent === field.id)
						)
						return A.of(radioFieldIso.reverseGet({ group, options }))
					}
					case 'RADIO_OPTION':
						return A.empty
					case 'DATE_FIELD':
						return A.of(dateFieldIso.reverseGet(field))
					case 'PHONE_NUMBER_FIELD':
						return A.of(phoneNumberFieldIso.reverseGet(field))
				}
			})
		)
)

export const formRegistryIso = new Iso<FormField[], FormRegistry>(
	A.reduceRight({} as FormRegistry, (field, registry) => ({ [field.id]: field, ...registry })),
	(registry) => Object.values(registry)
)

export const fieldAt = (id: UUID): Optional<FormRegistry, FormField> => {
	const fieldAt = atRecord<FormField>().at(id)
	const fieldAtPrism = Prism.some<FormField>()
	return fieldAt.composePrism(fieldAtPrism)
}

export const type = Lens.fromProp<FormField>()('type')

export const textField = new Prism<FormField, TextField>(
	(f) => (TextField.is(f) ? O.some(f) : O.none),
	identity
)

export const checkboxField = new Prism<FormField, CheckboxField>(
	(f) => (CheckboxField.is(f) ? O.some(f) : O.none),
	identity
)

export const radioGroup = new Prism<FormField, RadioGroup>(
	(f) => (RadioGroup.is(f) ? O.some(f) : O.none),
	identity
)

export const radioOption = new Prism<FormField, RadioOption>(
	(f) => (RadioOption.is(f) ? O.some(f) : O.none),
	identity
)

export const dateField = new Prism<FormField, DateField>(
	(f) => (DateField.is(f) ? O.some(f) : O.none),
	identity
)

export const phoneNumberField = new Prism<FormField, PhoneNumberField>(
	(f) => (PhoneNumberField.is(f) ? O.some(f) : O.none),
	identity
)

export const singleMappableField = new Prism<FormField, SingleMappableField>(
	(f) => (SingleMappableField.is(f) ? O.some(f) : O.none),
	identity
)

export const mappableField = new Prism<FormField, MappableField>(
	(f) => (MappableField.is(f) ? O.some(f) : O.none),
	identity
)

export const unmappableField = new Prism<FormField, UnmappableField>(
	(f) => (UnmappableField.is(f) ? O.some(f) : O.none),
	identity
)

export const visibleField = new Prism<FormField, VisibleField>(
	(f) => (VisibleField.is(f) ? O.some(f) : O.none),
	identity
)
