import { identity } from 'fp-ts/lib/function'
import * as E from 'fp-ts/lib/Either'
import * as t from 'io-ts'

import { FormFieldPosition } from './position'
import { inUnitInterval } from '../helpers'

const typeGuard = ((u) => {
	return FormFieldPosition.is(u) && inUnitInterval([u.x, u.y])
}) as (u: unknown) => u is FormFieldPosition

const validator = (u: unknown, c: t.Context): E.Either<t.Errors, FormFieldPosition> =>
	E.either.chain(FormFieldPosition.validate(u, c), (p) =>
		typeGuard(p) ? t.success(p) : t.failure(u, c)
	)

export const ServerFormFieldPosition = new t.Type<FormFieldPosition, unknown, unknown>(
	'ServerFormFieldPosition',
	typeGuard,
	validator,
	identity
)

export type ServerFormFieldPosition = t.TypeOf<typeof ServerFormFieldPosition>
