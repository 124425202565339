import Vuex from 'vuex'
import state from './state'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

import { nextSortOption, sortByOption } from '@/modules/forms/sorts'

const store = new Vuex.Store({
	state,
	getters,
	mutations,
	actions,
})

export const useFormsStore = () => store

export { nextSortOption, sortByOption }
