import * as O from 'fp-ts/lib/Option'
import { Iso, Lens, Optional } from 'monocle-ts'

import { emptyFormDefinition, formDefinitionIso } from './definition'
import { ServerForm } from './form.server'
import { Form } from './form'
import { emptyAdditionalInformation, additionalInformationIso } from './additional-information'

export const formIso = new Iso<ServerForm, Form>(
	(form: ServerForm) => ({
		id: form.id,
		name: form.name,
		definition: form.definition ? formDefinitionIso.get(form.definition) : emptyFormDefinition,
		pageCount: form.num_pages,
		updatedBy: O.fromNullable(form.last_modified_by),
		lastModified: O.fromNullable(form.last_modified),
		formType: O.fromNullable(form.form_type),
		formStatus: O.fromNullable(form.form_status),
		additionalInformation: form.additional_information
			? additionalInformationIso.get(form.additional_information)
			: emptyAdditionalInformation,
	}),
	(form: Form) => ({
		id: form.id,
		name: form.name,
		definition: formDefinitionIso.reverseGet(form.definition),
		num_pages: form.pageCount,
		form_type: O.toNullable(form.formType),
		form_status: O.toNullable(form.formStatus),
		last_modified_by: O.toNullable(form.updatedBy),
		last_modified: O.toNullable(form.lastModified),
		additional_information: additionalInformationIso.reverseGet(form.additionalInformation),
	})
)

export const id = Lens.fromProp<Form>()('id')
export const name = Lens.fromProp<Form>()('name')
export const pageCount = Lens.fromProp<Form>()('pageCount')
export const definition = Lens.fromProp<Form>()('definition')
export const updatedBy = Optional.fromOptionProp<Form>()('updatedBy')
export const formType = Optional.fromOptionProp<Form>()('formType')
export const formStatus = Optional.fromOptionProp<Form>()('formStatus')
export const lastModified = Optional.fromOptionProp<Form>()('lastModified')
export const additionalInformation = Lens.fromProp<Form>()('additionalInformation')
