import * as t from 'io-ts'

import { ServerDiscoveredNameMetadata, ServerNameMetadata } from '../../metadata'
import { ServerRequiredConstraint } from '../../constraints'
import { ServerDimensionalTrait } from '../../traits/dimensional'
import { ServerFirstClassTrait } from '../../traits/first-class'
import { ServerPaginatedTrait } from '../../traits/paginated'
import { ServerPositionalTrait } from '../../traits/positional'
import { ServerUnmappableTrait } from '../../traits/unmappable'
import { ServerElementRef } from '../../element'

// prettier-ignore
export const ServerTextField = t.intersection([
	t.type({
		type: t.literal('TEXT_FIELD'),
	}),
	t.partial({
		multiline: t.union([t.boolean, t.null]),
		default_value: t.union([t.string, t.null]),
		delimiter: t.union([t.string, t.null]),
		element: t.union([t.array(ServerElementRef), t.null]),
		constraints: ServerRequiredConstraint,
		metadata: t.intersection([
			ServerDiscoveredNameMetadata,
			ServerNameMetadata
		]),
	}),
	t.intersection([
		ServerDimensionalTrait,
		ServerFirstClassTrait,
		ServerPaginatedTrait,
		ServerPositionalTrait,
		ServerUnmappableTrait,
	]),
])

export type ServerTextField = t.TypeOf<typeof ServerTextField>
