import axios, { AxiosResponse, AxiosRequestConfig } from 'axios'
import { networkError, validationError } from './types'
import { useAuthStore } from '@/modules/auth/store'
import { tokenId } from '@/models/auth/user'

import { constant, flow } from 'fp-ts/lib/function'
import { pipe } from 'fp-ts/lib/function'
import * as TE from 'fp-ts/lib/TaskEither'
import * as E from 'fp-ts/lib/Either'
import * as O from 'fp-ts/lib/Option'
import * as t from 'io-ts'

const AUTHENTICATION_HEADER = 'X-Myndshft-RBAC-IDToken'

// TODO - this should be removed when we stop going directly to the api
axios.interceptors.request.use(async (config) => {
	const authStore = useAuthStore()

	await pipe(
		authStore.state.refresh,
		O.getOrElseW(() => Promise.resolve())
	)

	if (config.headers) {
		config.headers[AUTHENTICATION_HEADER] = pipe(
			authStore.state.user,
			O.map(tokenId.get),
			O.getOrElse(constant(''))
		)
	}
	return config
})

const _requestTask = (url: string, config?: AxiosRequestConfig) =>
	TE.tryCatch<Error, AxiosResponse<unknown>>(
		() => axios.request({ ...(config ?? {}), url }),
		E.toError
	)

// Lift the left sides of TaskEither and Either into a [[RequestError]]
export const requestTask = flow(_requestTask, TE.mapLeft(networkError))
const liftDecode = <A>(d: t.Decoder<unknown, A>) => flow(d.decode, E.mapLeft(validationError))

export const axiosRequest = async (url: string, config?: AxiosRequestConfig) => {
	return await axios.request({ ...(config ?? {}), url })
}

/**
 * Returns a TaskEither that performs an HTTP request and decodes (and
 * validates) the result with the given io-ts Decoder.
 */
export const requestAndDecodeTask = <A>(d: t.Decoder<unknown, A>) => {
	return flow(
		requestTask,
		TE.map((response) => response?.data),
		TE.chain(flow(liftDecode(d), TE.fromEither))
	)
}
