import * as t from 'io-ts'
import * as O from 'fp-ts/lib/Option'
import { option } from 'io-ts-types/lib/option'

export const AdditionalInformation = t.type({
	$schema: t.string,
	$id: t.string,
	title: option(t.string),
	description: option(t.string || null),
	required: t.array(t.string),
	type: t.string,
	properties: t.any,
})

export type AdditionalInformation = t.TypeOf<typeof AdditionalInformation>

export const emptyAdditionalInformation: AdditionalInformation = {
	$schema: '',
	$id: '',
	title: O.none,
	description: O.none,
	required: [],
	type: 'object',
	properties: {},
}
