import { State } from './state'
import { FormMetadata, FormMetaDataKey, LastModified } from '@/models/form/metadata'
import { pipe } from 'fp-ts/lib/function'
import { FormsListColumnSort } from '@/models/form/sorts'
import { sortByOption } from '@/modules/forms/sorts'

function allForms(state: State): FormMetadata[] {
	return state.forms
}

// This filters the forms depending on the search bar
const applySearchFilter =
	(searchText: string) =>
	(forms: FormMetadata[]): FormMetadata[] => {
		return [...forms].filter((form) => {
			const includesName = form.name.toLowerCase().includes(searchText.toLowerCase())
			const hasId = form?.id === searchText
			return includesName || hasId
		})
	}

// This sorts the forms depending on the selected sort
const applySort =
	(sort: FormsListColumnSort | null) =>
	(forms: FormMetadata[]): FormMetadata[] => {
		if (sort) {
			return [...forms].sort((formA: FormMetadata, formB: FormMetadata) => {
				const sortValues = sortByOption(sort.option)
				const valueA = formA[sort.name as FormMetaDataKey]
				const valueB = formB[sort.name as FormMetaDataKey]

				if (LastModified.is(valueA) || LastModified.is(valueB)) {
					const formADateValue: Date | null = LastModified.is(valueA) ? valueA.date : null
					const formBDateValue: Date | null = LastModified.is(valueB) ? valueB.date : null
					// Flipping the values here makes the most recent date sort to the top
					return sortValues(formBDateValue, formADateValue)
				} else {
					return sortValues(valueA, valueB)
				}
			})
		}
		return forms
	}

/**
 * Returns a list of forms that have the filters and sorting field applied
 * @param state
 */
function sortedAndFilteredForms(state: State): FormMetadata[] {
	return pipe(state.forms, applySearchFilter(state.searchText), applySort({ ...state.sortField }))
}

export default {
	allForms,
	sortedAndFilteredForms,
}
