import { Iso, Lens } from 'monocle-ts'

import { ServerSingleMappableTrait } from './single-mappable.server'
import { SingleMappableTrait } from './single-mappable'
import { elementRefIso } from '../../element'

export const element = Lens.fromProp<SingleMappableTrait>()('element')

export const singleMappableTraitIso = new Iso<ServerSingleMappableTrait, SingleMappableTrait>(
	(trait) => ({ element: elementRefIso.get(trait.element) }),
	(trait) => ({ element: elementRefIso.reverseGet(trait.element) })
)
