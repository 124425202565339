import { flow, pipe } from 'fp-ts/lib/function'
import * as A from 'fp-ts/lib/Array'
import * as O from 'fp-ts/lib/Option'
import { Iso, Lens } from 'monocle-ts'

import { ServerTextField } from './text.server'
import { TextField } from './text'

import { discoveredNameMetadataIso, nameMetadataIso } from '../../metadata'
import { requiredConstraintIso } from '../../constraints'
import { dimensionalTraitIso } from '../../traits/dimensional'
import { firstClassTraitIso } from '../../traits/first-class'
import { paginatedTraitIso } from '../../traits/paginated'
import { positionalTraitIso } from '../../traits/positional'
import { unmappableTraitIso } from '../../traits/unmappable'
import { elementRefIso } from '../../element'

export const multiline = Lens.fromProp<TextField>()('multiline')

export const defaultValue = Lens.fromProp<TextField>()('defaultValue')

export const delimiter = Lens.fromProp<TextField>()('delimiter')

export const element = Lens.fromProp<TextField>()('element')

export const constraints = {
	required: Lens.fromPath<TextField>()(['constraints', 'required']),
}

export const metadata = {
	discoveredName: Lens.fromPath<TextField>()(['metadata', 'discoveredName']),
	name: Lens.fromPath<TextField>()(['metadata', 'name']),
}

const defaultNameToDiscoveredName = (field: TextField) => {
	const discoveredName = metadata.discoveredName.get(field)
	const name = metadata.name.get(field)
	return metadata.name.set(O.alt(() => discoveredName)(name))(field)
}

export const textFieldIso = new Iso<ServerTextField, TextField>(
	flow(
		(field) => ({
			type: field.type,
			multiline: field.multiline ?? false,
			defaultValue: field.default_value ?? '',
			delimiter: field.delimiter ?? '',
			element: (field.element ?? []).map(elementRefIso.get),
			constraints: {
				...field.constraints, // preserve unused constraints
				...requiredConstraintIso.get({ ...field.constraints }),
			},
			metadata: {
				...field.metadata, // preserve unused metadata
				...discoveredNameMetadataIso.get({ ...field.metadata }),
				...nameMetadataIso.get({ ...field.metadata }),
			},
			...dimensionalTraitIso.get(field),
			...firstClassTraitIso.get(field),
			...paginatedTraitIso.get(field),
			...positionalTraitIso(field.size.y).get(field),
			...unmappableTraitIso.get(field),
		}),
		defaultNameToDiscoveredName
	),
	(field) => {
		const { discoveredName, name, ...unusedMetadata } = field.metadata
		const { required, ...unusedConstraints } = field.constraints

		return {
			type: field.type,
			multiline: field.multiline,
			default_value: field.defaultValue,
			delimiter: field.delimiter,
			element: pipe(field.element, A.compact, A.map(O.some), A.map(elementRefIso.reverseGet)),
			constraints: {
				...unusedConstraints,
				...requiredConstraintIso.reverseGet(field.constraints),
			},
			metadata: {
				...unusedMetadata,
				...discoveredNameMetadataIso.reverseGet(field.metadata),
				...nameMetadataIso.reverseGet(field.metadata),
			},
			...dimensionalTraitIso.reverseGet(field),
			...firstClassTraitIso.reverseGet(field),
			...paginatedTraitIso.reverseGet(field),
			...positionalTraitIso(field.dimensions.height).reverseGet(field),
			...unmappableTraitIso.reverseGet(field),
		}
	}
)
