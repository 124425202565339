import { pipe } from 'fp-ts/lib/function'
import { Iso, Lens } from 'monocle-ts'

import { ServerFormDefinition } from './definition.server'
import { FormDefinition } from './definition'

import { formFieldIso, formRegistryIso } from './field/optics'
import { formDefinitionMetadataIso, emptyFormDefinitionMetadata } from './metadata'

export const formDefinitionIso = new Iso<ServerFormDefinition, FormDefinition>(
	(definition) => ({
		fields: definition.fields ? pipe(definition.fields, formFieldIso.get, formRegistryIso.get) : {},
		metadata: definition.metadata
			? formDefinitionMetadataIso.get(definition.metadata)
			: emptyFormDefinitionMetadata,
	}),
	(definition) => ({
		fields: pipe(definition.fields, formRegistryIso.reverseGet, formFieldIso.reverseGet),
		metadata: formDefinitionMetadataIso.reverseGet(definition.metadata),
	})
)

export const fields = Lens.fromProp<FormDefinition>()('fields')
export const metadata = Lens.fromProp<FormDefinition>()('metadata')
