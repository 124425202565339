import { Machine, MachineConfig, StateMachine, assign } from 'xstate'
import { AxiosRequestConfig } from 'axios'

import { Context, Schema, Event, RequestOperation, RequestError } from './types'
import { requestAndDecodeTask } from './api'

import { constant } from 'fp-ts/lib/function'
import { pipe } from 'fp-ts/lib/function'
import * as E from 'fp-ts/lib/Either'
import * as O from 'fp-ts/lib/Option'
import * as t from 'io-ts'

const defaultContext: Context = {
	url: '',
	config: {
		method: 'GET',
	},
	runtimeConfig: {},
	downloadProgress: O.none,
	uploadProgress: O.none,
	result: O.none,
	decoder: t.unknown,
}

const config: MachineConfig<Context, Schema, Event> = {
	id: 'requestMachine',
	initial: 'idle',
	context: defaultContext,
	states: {
		idle: {
			on: {
				DISPATCH: 'loading',
				SET_CONFIG: {
					actions: assign({
						runtimeConfig: (_, e) => e.config,
					}),
				},
			},
		},
		loading: {
			invoke: {
				id: 'request',
				src: 'request',
			},

			on: {
				DOWNLOAD_UPDATE: {
					actions: assign({
						downloadProgress: (_, e) => O.some(e.progress),
					}),
				},
				UPLOAD_UPDATE: {
					actions: assign({
						uploadProgress: (_, e) => O.some(e.progress),
					}),
				},
				RESOLVE: {
					target: 'success',
					actions: assign({
						result: (_, e) => O.some(E.right(e.data)),
					}),
				},
				REJECT: [
					{
						target: 'failure.networkError',
						cond: 'isNetworkError',
						actions: assign({
							result: (_, e) => O.some(E.left(e.error)),
						}),
					},
					{
						target: 'failure.validationError',
						cond: 'isValidationError',
						actions: assign({
							result: (_, e) => O.some(E.left(e.error)),
						}),
					},
				],
			},
		},
		success: {
			on: {
				RESET: 'idle',
			},
		},
		failure: {
			states: {
				networkError: {},
				validationError: {},
			},

			on: {
				RETRY: 'loading',
				RESET: 'idle',
			},
		},
	},
}

const guards = {
	isNetworkError(_: Context, e: Event) {
		return e.type === 'REJECT' && e.error.type === 'NETWORK'
	},
	isValidationError(_: Context, e: Event) {
		return e.type === 'REJECT' && e.error.type === 'VALIDATION'
	},
}

const services = {
	// eslint-disable-next-line @typescript-eslint/ban-types
	request: (context: Context) => (callback: Function) => {
		const config: AxiosRequestConfig = {
			...context.config,
			...context.runtimeConfig,
			onDownloadProgress(e: ProgressEvent) {
				context.downloadProgress = O.some(e.loaded / e.total)
			},
			onUploadProgress(e: ProgressEvent) {
				context.uploadProgress = O.some(e.loaded / e.total)
			},
		}

		requestAndDecodeTask(context.decoder)(context.url, config)().then(
			E.fold(
				(error) => callback({ type: 'REJECT', error }),
				(data) => callback({ type: 'RESOLVE', data })
			)
		)
	},
}

/**
 * Factory function that creates a request machine with the URL, request
 * configuration, and io-ts decoder set in the context. This machine dispatches
 * an HTTP request and validates the response against the given runtime type.
 *
 * ``` html
 * <template>
 *   <div>
 *     <button @click="send('DISPATCH')">Fetch Data</button>
 *     <p v-if="state.matches('loading')">Loading...<p>
 *     <p v-else-if="state.matches('success')">{{ state.context.data }}</p>
 *     <p v-else-if="state.matches('failure.networkError')">Network Error</p>
 *     <p v-else-if="state.matches('failure.validationError')">Type Error</p>
 *   </div>
 * </template>
 * ```
 *
 * ``` ts
 * import { useMachine } from '@xstate/vue'
 * import { createRequestMachine } from '@/modules/http'
 * import { SomeRuntimeType } from '@/models/path/to/type'
 *
 * export default defineComponent({
 *   setup() {
 *     const endpoint = 'https://api.foobar.com/endpoint'
 *     const requestMachine = createRequestMachine(endpoint, SomeRuntimeType)
 *     return useMachine(requestMachine)
 *   }
 * })
 * ```
 */
export function createRequestMachine<T>(
	url: string,
	decoder: t.Decoder<unknown, T>,
	axiosConfig?: AxiosRequestConfig
) {
	return Machine<Context, Schema, Event>(config, { guards, services }).withContext({
		...defaultContext,
		config: axiosConfig ?? {},
		decoder,
		url,
	}) as StateMachine<Context<T>, Schema, Event>
}

/**
 * Unwraps the download/upload progress of an HTTP request.
 */
export const unwrapProgress = (progress: O.Option<number>) =>
	pipe(
		progress,
		O.getOrElse(() => 0)
	)

/**
 * Unwraps the data from a [[RequestOperation]].
 */
export const unwrapData: <T>(operation: RequestOperation<T>) => O.Option<T> =
	// Unwrap Option
	O.fold(
		constant(O.none),
		// Unwrap Either
		E.fold(constant(O.none), O.some)
	)

/**
 * Unwraps the error from a [[RequestOperation]].
 */
export const unwrapError: <T>(operation: RequestOperation<T>) => O.Option<RequestError> =
	// Unwrap Option
	O.fold(
		constant(O.none),
		// Unwrap Either
		E.fold(O.some, constant(O.none))
	)
