import { v4 as uuidv4 } from 'uuid'

import { identity, pipe } from 'fp-ts/lib/function'
import * as A from 'fp-ts/lib/Array'
import * as O from 'fp-ts/lib/Option'
import { UUID } from 'io-ts-types/lib/UUID'
import * as t from 'io-ts'
import { Iso, Lens, Optional, Prism } from 'monocle-ts'

import { ServerRadioField, ServerRadioConditional } from './radio.server'
import {
	RadioGroup,
	RadioOption,
	RadioConditional,
	enumConditionalValue,
	booleanConditionalValue,
	RadioEnumConditional,
	RadioBooleanConditional,
} from './radio'

import { discoveredNameMetadataIso, nameMetadataIso } from '../../metadata'
import { requiredConstraintIso } from '../../constraints'
import { firstClassTraitIso } from '../../traits/first-class'
import { mappableTraitIso } from '../../traits/mappable'
import { paginatedTraitIso } from '../../traits/paginated'
import { positionalTraitIso } from '../../traits/positional'
import { dimensionalTraitIso } from '../../traits/dimensional'
import { unmappableTraitIso } from '../../traits/unmappable'

const RadioField = t.type({
	group: RadioGroup,
	options: t.array(RadioOption),
})

type RadioField = t.TypeOf<typeof RadioField>

const conditionalIso = new Iso<ServerRadioConditional, RadioConditional>(
	(c) => ({
		conditional:
			'conditional_id' in c
				? O.some(enumConditionalValue((c.conditional_id ?? []).map(O.fromNullable)))
				: 'conditional_value' in c
				? pipe(c.conditional_value, O.fromNullable, O.map(booleanConditionalValue))
				: O.none,
	}),
	(c) =>
		pipe(
			c.conditional,
			O.map((conditional) => {
				switch (conditional.type) {
					case 'ENUM':
						return { conditional_id: A.compact(conditional.ids) }
					case 'BOOLEAN':
						return { conditional_value: conditional.value }
				}
			}),
			O.getOrElse(() => ({ conditional_id: [] } as ServerRadioConditional))
		)
)

export const radioFieldIso = new Iso<ServerRadioField, RadioField>(
	(field) => {
		const { options: serverOptions, ...serverGroup } = field

		const group: RadioGroup = {
			type: 'RADIO_GROUP',
			constraints: {
				...serverGroup.constraints, // preserve unused constraints
				...requiredConstraintIso.get({ ...serverGroup.constraints }),
			},
			metadata: {
				...serverGroup.metadata, // preserve unused metadata
				...discoveredNameMetadataIso.get({ ...serverGroup.metadata }),
				...nameMetadataIso.get({ ...serverGroup.metadata }),
			},
			...firstClassTraitIso.get(serverGroup),
			...mappableTraitIso.get(serverGroup),
			...unmappableTraitIso.get(serverGroup),
		}

		const options: RadioOption[] = serverOptions.map((option) => ({
			type: 'RADIO_OPTION',
			id: option.metadata?.id ?? (uuidv4() as UUID),
			parent: group.id,
			value: option.value,
			metadata: {
				...option.metadata, // preserve unused metadata
				...nameMetadataIso.get({ ...option.metadata }),
			},
			...conditionalIso.get(option),
			...dimensionalTraitIso.get(option),
			...paginatedTraitIso.get(option),
			...positionalTraitIso(option.size.y).get(option),
		}))

		return { group, options }
	},
	({ group, options }) => {
		const { discoveredName, name, ...unusedMetadata } = group.metadata
		const { required, ...unusedConstraints } = group.constraints

		return {
			type: 'RADIO_BUTTON',
			constraints: {
				...unusedConstraints,
				...requiredConstraintIso.reverseGet(group.constraints),
			},
			metadata: {
				...unusedMetadata,
				...discoveredNameMetadataIso.reverseGet(group.metadata),
				...nameMetadataIso.reverseGet(group.metadata),
			},
			options: options.map((option) => {
				const { name, ...unusedMetadata } = group.metadata

				return {
					value: option.value,
					metadata: {
						...unusedMetadata,
						...nameMetadataIso.reverseGet(option.metadata),
						id: option.id,
					},
					...conditionalIso.reverseGet(option),
					...dimensionalTraitIso.reverseGet(option),
					...paginatedTraitIso.reverseGet(option),
					...positionalTraitIso(option.dimensions.height).reverseGet(option),
				}
			}),
			...firstClassTraitIso.reverseGet(group),
			...mappableTraitIso.reverseGet(group),
			...unmappableTraitIso.reverseGet(group),
		}
	}
)

export const conditional = Lens.fromProp<RadioOption>()('conditional')
export const conditionalOptional = Optional.fromOptionProp<RadioOption>()('conditional')

export const enumConditional = new Prism<
	RadioBooleanConditional | RadioEnumConditional,
	RadioEnumConditional
>((c) => (c.type === 'ENUM' ? O.some(c) : O.none), identity)
