import { Iso, Lens } from 'monocle-ts'

import { ServerFormFieldDimensions } from './dimensions.server'
import { FormFieldDimensions } from './dimensions'

export const formFieldDimensionsIso = new Iso<ServerFormFieldDimensions, FormFieldDimensions>(
	(dimensions) => ({
		width: dimensions.x,
		height: dimensions.y,
	}),
	(dimensions) => ({
		x: dimensions.width,
		y: dimensions.height,
	})
)

export const width = Lens.fromProp<FormFieldDimensions>()('width')
export const height = Lens.fromProp<FormFieldDimensions>()('height')
