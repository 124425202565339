import { FeaturesState } from './state'
import { FeatureFlags, FeatureFlagKeys } from '@/modules/flags'

function featureFlags(state: FeaturesState): FeatureFlags {
	return state.featureFlags
}

function featureFlagKeys(state: FeaturesState): FeatureFlagKeys {
	return state.featureFlagKeys
}

export const getters = { featureFlags, featureFlagKeys }
