import { ref, computed, ComputedRef, Ref } from 'vue'
import { Store } from 'vuex'
import { useMyndmapApi } from '@/modules/api'
import { FeatureFlags, FeatureFlagKey, FeatureFlagKeys } from '@/modules/flags/types'

import * as E from 'fp-ts/Either'

const FEATURE_FLAG_POLLING_INTERVAL = 60 * 1000
const featureFlagPollingRef = ref<any>()

/**
 * Setup and return a feature flag request
 */
const featureFlagRequest = (config: Ref) => {
	const { getFeatureFlags } = useMyndmapApi(config)
	return getFeatureFlags
}

const retrieveFeatureFlags = (store: Store<unknown>, config: Ref, keys: FeatureFlagKeys) => {
	const request = featureFlagRequest(config)
	request(keys)().then(
		E.fold(
			() => store.commit('updateFeatureFlags', {}),
			(result) => store.commit('updateFeatureFlags', result.data)
		)
	)
}

const clearFeatureFlagInterval = () => {
	if (featureFlagPollingRef.value) clearInterval(featureFlagPollingRef.value)
}

/**
 * Setup the feature flag functions
 */
export const useFeatureFlags = (store: Store<unknown>) => ({
	/**
	 * Used to stop the polling for feature flags external to this module
	 */
	stopFeatureFlagPolling: () => clearFeatureFlagInterval,

	/**
	 * This function adds the discovered flag from the component and returns a computed
	 * representation of the flag. Initially the value is set to off (false), but after the request is
	 * made to fetch the feature flags, this value will be updated reactively.
	 * @param flagKey
	 */
	isActiveFeature: (flagKey: FeatureFlagKey): ComputedRef => {
		store.commit('addUniqueFeatureFlagKey', flagKey)
		const featureFlags = computed<FeatureFlags>(() => store.getters['featureFlags'])
		return computed<boolean>(() => featureFlags.value[flagKey])
	},

	/**
	 * Initial setup of the feature flags, a computed value is created of the flags keys.
	 * As they are used in the components, they are dynamically added to the store, triggering
	 * the watch. This causes the polling to stop, we fetch the feature flags instantly,
	 * then restart the polling.
	 */
	startFeatureFlagPolling: (config: Ref) => {
		const featureFlagKeysRef = computed<FeatureFlagKeys>(
			() => store.getters['featureFlagKeys'] || []
		)

		if (featureFlagKeysRef.value.length > 0) {
			retrieveFeatureFlags(store, config, featureFlagKeysRef.value)
			clearFeatureFlagInterval()
			featureFlagPollingRef.value = setInterval(
				() => retrieveFeatureFlags(store, config, featureFlagKeysRef.value),
				FEATURE_FLAG_POLLING_INTERVAL
			)
		}
	},
})
