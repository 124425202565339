import { AxiosResponse } from 'axios'
import { computed, Ref } from 'vue'
import {
	createRequestMachine,
	requestAndDecodeTask,
	requestTask,
	axiosRequest,
} from '@/modules/http'
import { Form, formIso, ServerForm } from '@/models/form'
import { DataElement } from '@/models/data/elements'
import { constructDataModel } from '@/models/data/model'
import { myndConfigError, requestError } from './types'

import { pipe } from 'fp-ts/lib/function'
import * as A from 'fp-ts/lib/Array'
import * as O from 'fp-ts/lib/Option'
import * as TE from 'fp-ts/lib/TaskEither'
import * as E from 'fp-ts/Either'

import { UUID } from 'io-ts-types/lib/UUID'
import * as t from 'io-ts'

import * as PIXI from 'pixi.js-legacy'
import { EnvironmentConfig } from '@/models/environment'
import { FeatureFlagKeys } from '@/modules/flags'
import { getBFFRoute, getCredentials } from '@/modules/helpers/http'

// API Server Endpoints
// https://bitbucket.org/myndshft/cbclaims-pdf-mapper/src/master/docs/
const configEndpoint = 'config'
const formListEndpoint = 'forms'
const formEndpoint = (id: UUID) => `forms/${id}`
const formCopyEndpoint = (formId: UUID) => `forms/${formId}/duplicate`
const formPageEndpoint = (id: UUID) => (index: number) => `forms/${id}/page/${index}.png`
const dataModelEndpoint = `model`
const featureFlagsEndpoint = `feature_flags`
const AUTHENTICATION_HEADER = 'X-Myndshft-RBAC-IDToken'

export const getConfig = () =>
	pipe(
		getBFFRoute(),
		(apiServer: string) => {
			return TE.of(`${apiServer}/api/${configEndpoint}`)
		},
		TE.chain((endpoint) =>
			pipe(
				requestTask(endpoint, {
					method: 'GET',
				}),
				TE.mapLeft(requestError),
				TE.map((response) => {
					return response.data
				})
			)
		)
	)

// This is to start converting to axios calls over the fp-ts calls
export function useMyndmapApiV2() {
	const createRoute = (apiServer: string, path: string): string => `${apiServer}/api/${path}`

	const loginRequest = async (user: any) => {
		try {
			const credentials = getCredentials()
			const response = await axiosRequest(createRoute(getBFFRoute(), 'auth/login'), {
				method: 'get',
				headers: { [AUTHENTICATION_HEADER]: user.token },
				...credentials,
			})
			return response?.data || null
		} catch (_error) {
			return null
		}
	}

	const checkActiveRequest = async () => {
		try {
			const credentials = getCredentials()
			const response = await axiosRequest(createRoute(getBFFRoute(), 'check_active'), {
				method: 'get',
				...credentials,
			})
			return response?.data || null
		} catch (_error) {
			return null
		}
	}

	const logoutRequest = async () => {
		try {
			const response = await axiosRequest(createRoute(getBFFRoute(), 'auth/logout'), {
				method: 'get',
				...getCredentials(),
			})
			return response?.data || null
		} catch (_error) {
			return null
		}
	}

	return {
		login: async (user: any) => await loginRequest(user),
		logout: async () => await logoutRequest(),
		checkActive: async () => await checkActiveRequest(),
	}
}

/**
 * Composition function that exposes an interface of exported functions for
 * interacting with the Myndmap API server using the hostname configured in
 * Mynd Config.
 */
export function useMyndmapApi(config: Ref<EnvironmentConfig>) {
	// Factory function for creating "Request and Decode" tasks for GET requests
	const createGet =
		<T>(decoder: t.Decoder<unknown, T>, serverEndpoint?: string) =>
		(endpoint: string) => {
			return pipe(
				serverEndpoint,
				(endpoint) => {
					return endpoint
						? O.of(endpoint)
						: pipe(
								O.of(config.value),
								O.map((c) => c.apiServer)
						  )
				},
				TE.fromOption(myndConfigError),
				TE.map((apiServer) => `${apiServer}/${endpoint}`),
				TE.chain((url) => pipe(requestAndDecodeTask(decoder)(url), TE.mapLeft(requestError)))
			)
		}

	const createPut =
		(endpoint: string) =>
		<T>(data: T) =>
			pipe(
				O.of(config.value),
				O.map((c) => c.apiServer),
				TE.fromOption(myndConfigError),
				TE.map((apiServer) => `${apiServer}/${endpoint}`),
				TE.chain((url) => pipe(requestTask(url, { method: 'PUT', data }), TE.mapLeft(requestError)))
			)

	const createPatch =
		(endpoint: string, bff?: boolean) =>
		<T>(data: T) =>
			pipe(
				O.of(config.value),
				O.map((c) => c.apiServer),
				TE.fromOption(myndConfigError),
				TE.map((apiServer) => `${bff ? bffBase : apiServer}/${endpoint}`),
				TE.map((url: string) => {
					if (!Object.keys(data).length) {
						return [url, null]
					} else {
						return [url, { update_mask: Object.keys(data).join(',') }]
					}
				}),
				TE.chain(([url, params]) =>
					pipe(
						requestTask(
							url as string,
							params ? { method: 'PATCH', params, data } : { method: 'PATCH', data }
						),
						TE.mapLeft(requestError)
					)
				)
			)

	const createCopy = (endpoint: string, bodyFormData: any) =>
		pipe(
			O.of(config.value),
			O.map((c) => c.apiServer),
			TE.fromOption(myndConfigError),
			TE.map((apiServer) => `${apiServer}/${endpoint}`),
			TE.chain((url) =>
				pipe(
					requestTask(url, {
						method: 'POST',
						data: bodyFormData,
					}),
					TE.mapLeft(requestError)
				)
			)
		)

	const createDelete = (endpoint: string) =>
		pipe(
			O.of(config.value),
			O.map((c) => c.apiServer),
			TE.fromOption(myndConfigError),
			TE.map((apiServer) => `${apiServer}/${endpoint}`),
			TE.chain((url) => pipe(requestTask(url, { method: 'DELETE' }), TE.mapLeft(requestError)))
		)

	// Project the server hostname from Mynd Config
	const apiServer = computed(() =>
		pipe(
			O.of(config.value),
			O.map((c) => c.apiServer),
			E.fromOption(myndConfigError)
		)
	)

	const bffBase = getBFFRoute() + '/api'

	return {
		/**
		 * Get the list of forms stored in the database.
		 */
		getFormList: () =>
			pipe(createGet(t.array(ServerForm))(formListEndpoint), TE.map(A.map(formIso.get))),

		/**
		 * Get the API endpoint for fetching the list of forms stored in the
		 * database.
		 */
		getFormListEndpoint: () =>
			pipe(
				apiServer.value,
				E.map((apiServer) => [apiServer, formListEndpoint].join('/'))
			),

		/**
		 * Return an xstate machine for the getting the list of forms stored in the
		 * database.
		 */
		getFormListMachine: () =>
			pipe(
				apiServer.value,
				E.map((apiServer) => [apiServer, formListEndpoint].join('/')),
				E.map((endpoint) => createRequestMachine(endpoint, t.array(ServerForm)))
			),

		/**
		 * Get the description and definition of a form.
		 */
		getForm: (id: UUID) => pipe(createGet(ServerForm)(formEndpoint(id)), TE.map(formIso.get)),

		/**
		 * Downloads the pages of a form and loads them as PIXI.js sprites.
		 */
		getFormPages: (urls: string[]) =>
			pipe(
				urls,
				A.map((endpoint) => {
					return pipe(
						requestTask(endpoint, { responseType: 'blob' }),
						TE.map((blob) => {
							const page = document.createElement('img')
							page.src = URL.createObjectURL((blob as AxiosResponse<Blob>).data)
							return page
						})
					)
				}),
				A.array.sequence(TE.taskEitherSeq),
				TE.map(
					A.map((image) => {
						const texture = PIXI.Texture.from(image)
						return PIXI.Sprite.from(texture)
					})
				)
			),

		/**
		 * Get the image URL of a form page.
		 */
		getFormPageEndpoint: (id: UUID) => (index: number) =>
			pipe(
				apiServer.value,
				E.map((apiServer) => [apiServer, formPageEndpoint(id)(index)].join('/'))
			),

		/**
		 * Get the image URLs of a form.
		 */
		getFormPageEndpoints: (id: UUID) => (numPages: number) =>
			pipe(
				apiServer.value,
				E.map((apiServer) =>
					pipe(
						A.range(1, numPages),
						A.map((i) => i - 1),
						A.map((i) => [apiServer, formPageEndpoint(id)(i)].join('/'))
					)
				)
			),

		/**
		 * Get the Myndshft data model -- the set of data elements that can be
		 * mapped to form fields.
		 */
		getDataModel: () =>
			pipe(createGet(t.array(DataElement))(dataModelEndpoint), TE.map(constructDataModel)),

		/**
		 * Updates the form details with a patch.
		 * NOTE: the keys in details must match specifications for the patch masks
		 * @param id
		 */
		updateFormDetails:
			(id: UUID) => (details: Record<string, { oldValue: any; newValue: any }>) => {
				const endpoint = formEndpoint(id)
				const detailsBody: Record<string, any> = {}
				Object.entries(details).forEach(([key, value]) => (detailsBody[key] = value.newValue))
				return createPatch(endpoint, true)(detailsBody)
			},

		/**
		 * Saves the form to the server.
		 */
		saveForm: (id: UUID, form: Form) => {
			const endpoint = formEndpoint(id)
			const serverForm = formIso.reverseGet(form)
			return createPut(endpoint)(serverForm)
		},

		/**
		 * Saves additional information. Also saves the definition in the event that the form mapping got updated.
		 */
		saveFormAdditionalInformation: (id: UUID, form: Form, definitionIncluded?: boolean) => {
			const endpoint = formEndpoint(id)
			const serverForm = formIso.reverseGet(form)
			if (definitionIncluded) {
				return createPatch(endpoint)({
					additional_information: serverForm.additional_information,
					definition: serverForm.definition,
				})
			} else {
				return createPatch(endpoint)({
					additional_information: serverForm.additional_information,
				})
			}
		},

		/**
		 * Saves definition as a patch on the given form
		 */
		saveFormDefinition: (id: UUID, form: Form) => {
			const endpoint = formEndpoint(id)
			const serverForm = formIso.reverseGet(form)
			return createPatch(endpoint)({
				definition: serverForm.definition,
			})
		},

		/**
		 * Copies the form by id with given name.
		 */
		copyForm: (row: any, newName: string) => {
			const endpoint = formCopyEndpoint(row.id)
			const bodyFormData = new FormData()
			bodyFormData.append('name', newName)
			return createCopy(endpoint, bodyFormData)
		},

		/**
		 * Deletes the form by id.
		 */
		deleteForm: (id: UUID) => {
			const endpoint = formEndpoint(id)
			return createDelete(endpoint)
		},

		/**
		 * Get the feature flags
		 */
		getFeatureFlags: (flags: FeatureFlagKeys) =>
			pipe(
				apiServer.value,
				E.map((apiServer) => [apiServer, featureFlagsEndpoint].join('/')),
				TE.fromEither,
				TE.chainW((endpoint) =>
					pipe(
						requestTask(endpoint, {
							method: 'GET',
							responseType: 'json',
							params: { keys: flags.join(',') },
						}),
						TE.mapLeft(requestError)
					)
				)
			),
	}
}
