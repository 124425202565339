import { identity } from 'fp-ts/lib/function'
import * as E from 'fp-ts/lib/Either'
import * as t from 'io-ts'

import { inUnitInterval } from '../helpers'

const UnconstrainedDimensions = t.type({
	x: t.number,
	y: t.number,
})

type UnconstrainedDimensions = t.TypeOf<typeof UnconstrainedDimensions>

const typeGuard = ((u) => {
	return UnconstrainedDimensions.is(u) && inUnitInterval([u.x, u.y])
}) as (u: unknown) => u is UnconstrainedDimensions

const validator = (u: unknown, c: t.Context): E.Either<t.Errors, UnconstrainedDimensions> =>
	E.either.chain(UnconstrainedDimensions.validate(u, c), (d) =>
		typeGuard(d) ? t.success(d) : t.failure(u, c)
	)

export const ServerFormFieldDimensions = new t.Type<UnconstrainedDimensions, unknown, unknown>(
	'ServerFormFieldDimensions',
	typeGuard,
	validator,
	identity
)

export type ServerFormFieldDimensions = t.TypeOf<typeof ServerFormFieldDimensions>
