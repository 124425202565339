import { ConfigState } from '@/modules/mynd-config/store/state'
import { EnvironmentConfig } from '@/models/environment'

function setConfig(state: ConfigState, config: EnvironmentConfig) {
	state.config = config
}

export const mutations = {
	setConfig,
}
