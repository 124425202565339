import * as O from 'fp-ts/lib/Option'
import { UUID } from 'io-ts-types/lib/UUID'
import { option } from 'io-ts-types/lib/option'
import * as t from 'io-ts'

import { DiscoveredNameMetadata, NameMetadata } from '../../metadata'
import { RequiredConstraint } from '../../constraints'
import { FormFieldPosition } from '../../position'
import { FormFieldDimensions } from '../../dimensions'
import { DimensionalTrait } from '../../traits/dimensional'
import { FirstClassTrait } from '../../traits/first-class'
import { PaginatedTrait } from '../../traits/paginated'
import { PositionalTrait } from '../../traits/positional'
import { UnmappableTrait } from '../../traits/unmappable'
import { ElementRef } from '../../element'

// prettier-ignore
export const CheckboxField = t.intersection([
	t.type({
		type: t.literal('CHECK_BOX'),
		conditionalId: t.array(option(t.string)),
		conditionalValue: t.union([t.boolean, t.null, t.undefined]),
		constraints: RequiredConstraint,
		element: t.array(ElementRef),
		metadata: t.intersection([
			DiscoveredNameMetadata,
			NameMetadata
		]),
	}),
	t.intersection([
		DimensionalTrait,
		FirstClassTrait,
		PaginatedTrait,
		PositionalTrait,
		UnmappableTrait,
	]),
])

export type CheckboxField = t.TypeOf<typeof CheckboxField>

export const createCheckboxField = (
	id: UUID,
	name: O.Option<string>,
	page: number,
	position: FormFieldPosition,
	dimensions: FormFieldDimensions
): CheckboxField => ({
	id,
	type: 'CHECK_BOX',
	mappable: true,
	element: [],
	conditionalId: [],
	conditionalValue: false,
	constraints: {
		required: false,
	},
	metadata: {
		discoveredName: O.none,
		name,
	},
	page,
	position,
	dimensions,
})
